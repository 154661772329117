import state from './proyectState'
import actions from './proyectActions'
import mutations from './proyectMutations'
import getters from './proyectGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
