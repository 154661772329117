import axios from '@/resources/libs/axios'

export default {
    searchByMasterInstallation(id) {
        return axios.get(`/vhe/children-installation/list/master-installation/${id}`)
    },
    find(id, data) {
        return axios.get(`/vhe/children-installation/find/${id}`, {params: data})
    },
    listAll(data) {
        return axios.get(`/vhe/children-installation/list`, { params: data })
    },
    getAllList(data) {
        return axios.get(`/vhe/children-installation/getAllList`, { params: data, timeout: 300000 })
    },
    update(data) {
        return axios.post(`/vhe/children-installation/update`,  data)
    },
    create(data) {
        return axios.post(`/vhe/children-installation/create`,  data)
    },
    delete(id) {
        return axios.delete(`/vhe/children-installation/delete/${id}`)
    },
    exportAllList(data) {
        return axios.get(`/vhe/children-installation/exportAllList`, { params: data,  responseType: 'blob' })
    },
}