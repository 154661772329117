import { VHE_CHILDREN_INSTALLATIONS_LIST,VHE_CHILDREN_INSTALLATIONS_CREATE,VHE_CHILDREN_INSTALLATIONS_EDIT } from '../../constants'

export default [
    {
        path: VHE_CHILDREN_INSTALLATIONS_LIST,
        name: 'list_children_installations_vhe',
        component: () => import('@/views/vhe/children_installations/list/listView.vue'),
        meta: {
            pageTitle: 'Listar Instalaciones Hijas VHE',
            permission: 'list_vhe_children',
            breadcrumb: [
                {
                    text: 'Listado de instalaciones Hijas VHE',
                    active: true,
                },
            ],
        },
    },
    {
        path: VHE_CHILDREN_INSTALLATIONS_CREATE,
        name: 'create_children_installation_vhe',
        component: () => import('@/views/vhe/children_installations/form/formView.vue'),
        meta: {
            pageTitle: 'Añadir nueva Instalación Hija VHE',
            permission: 'create_vhe_children',
            breadcrumb: [
                {
                    text: 'Instalaciones VHE',
                    to: VHE_CHILDREN_INSTALLATIONS_LIST,
                },
                {
                    text: 'Añadir nueva Instalación Hija',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${VHE_CHILDREN_INSTALLATIONS_EDIT}/:id`,
        name: 'children_installations_vhe_edit',
        component: () => import('@/views/vhe/children_installations/form/formView.vue'),
        meta: {
            pageTitle: 'Instalación Hija',
            permission: 'Dashboard',
            breadcrumb: [
                {
                    text: 'Instalaciones Hijas',
                    to: VHE_CHILDREN_INSTALLATIONS_LIST,
                },
                {
                    text: 'Mantenimiento VHE',
                    active: true,
                },
            ],
        },
    },
]
