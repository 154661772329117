import axios from '@/resources/libs/axios'

export default {
    list(data) {
        return axios.get(`/vhe/auxiliaries/levels/level3/list/all`, { params: data })
    },
    search(data) {
        return axios.get(`/vhe/auxiliaries/levels/level3/list`,{ params: data })
    },
    find(data) {
        return axios.get(`/vhe/auxiliaries/levels/level3/find/${data.id}`,{ params: data })
    },
    create(data) {
        return axios.post(`/vhe/auxiliaries/levels/level3/create`,  data)
    },
    update(data) {
        return axios.post(`/vhe/auxiliaries/levels/level3/update`,  data)
    },
    delete(id) {
        return axios.delete(`/vhe/auxiliaries/levels/level3/delete/${id}`)
    },
}