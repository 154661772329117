export const getDefaultState = () => {
    return {
        levels: [],
        level: {
            name: null,
        },
        validation_level_4: {
            name: null,
        },
        search:{
            name: null,
        }
    }
}
export default getDefaultState()
