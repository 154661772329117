import api from "@/router/api"

export default {
    getAllProviders({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.list(payload)
            .then(response => {
                resolve(response.data.data)
                commit('SET_PROVIDERS', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener el proveedor',
                })
            })
        })
    },
    searchProvider({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.search(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_PROVIDERS', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 2',
                    })
                })
        })
    },
    getProvider({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_PROVIDER', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el proveedor',
                    })
                })
        })
    },
    createProviders({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_PROVIDERS', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateProviders({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteProvider({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el proveedor.',
                    })
                })
        })
    },
    getCountry({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProvincesCountries.listCountries(payload)
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    getProvince({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProvincesCountries.listByCountry(payload)
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    getContactsProviders({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.listContactsProviders(payload.id, payload)
                .then(response => {

                    resolve(response.data.data)
                    //commit('SET_PROVIDERS', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el proveedor',
                    })
                })
        })
    },
    createContactProvider({commit}, payload){
        commit('CLEAR_VALIDATION_CONTACT')
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.createContact(payload.contact)
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_CONTACTS_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateContactProvider({commit}, payload){
        commit('CLEAR_VALIDATION_CONTACT')
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.updateContact(payload.contact)
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_CONTACTS_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteContactProvider({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesProviders.deleteContact(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el contacto.',
                    })
                })
        })
    },
}
