import { CHILDREN_INSTALLATIONS_LIST,CHILDREN_INSTALLATIONS_CREATE,CHILDREN_INSTALLATIONS_EDIT } from '../../constants'

export default [
    {
        path: CHILDREN_INSTALLATIONS_LIST,
        name: 'list_children_installations',
        component: () => import('@/views/gei/children_installations/list/listView.vue'),
        meta: {
            pageTitle: 'Listar Instalaciones Hijas GEI',
            permission: 'list_gei_children',
            breadcrumb: [
                {
                    text: 'Listado de instalaciones GEI',
                    active: true,
                },
            ],
        },
    },
    {
        path: CHILDREN_INSTALLATIONS_CREATE,
        name: 'create_children_installation',
        component: () => import('@/views/gei/children_installations/form/formView.vue'),
        meta: {
            pageTitle: 'Añadir nueva Instalación Hija GEI',
            permission: 'create_gei_children',
            breadcrumb: [
                {
                    text: 'Instalaciones GEI',
                    to: CHILDREN_INSTALLATIONS_LIST,
                },
                {
                    text: 'Añadir nueva Instalación Hija',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${CHILDREN_INSTALLATIONS_EDIT}/:id`,
        name: 'children_installations_gei_edit',
        component: () => import('@/views/gei/children_installations/form/formView.vue'),
        meta: {
            pageTitle: 'Instalación Hija',
            permission: 'Dashboard',
            breadcrumb: [
                {
                    text: 'Instalaciones Hijas',
                    to: CHILDREN_INSTALLATIONS_LIST,
                },
                {
                    text: 'Mantenimiento GEI',
                    active: true,
                },
            ],
        },
    },
]
