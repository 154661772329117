import {updateField} from "vuex-map-fields"
import helper from "@/resources/helpers/helpers"

export default {
	updateField,
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE(state, payload) {
		state.documents_by_route_maintenance = payload
	},
	SET_DOCUMENTS_MAINTENANCE(state, payload) {
		state.documents_maintenance = payload

		Object.entries(payload).forEach(([key, value]) => {

			state.documents_maintenance[key] = value

			let dates = ['effective_date', 'expected_date', 'report_review_date']
			dates.forEach(date => {
				state.documents_maintenance[key][date] = (value[date]) ? helper.formatDate(value[date]) : null
			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INSPECTION(state, payload) {
		state.documents_by_route_maintenance_inspection = payload
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INSITU(state, payload) {
		state.documents_by_route_maintenance_insitu = payload
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INVIGILANDO(state, payload) {
		state.documents_by_route_maintenance_invigilando = payload
	},
	SET_DOCUMENT_INSPECTION(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_inspection = [formattedPayload]

	},
	SET_DOCUMENT_INSITU(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object
		
		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_insitu = [formattedPayload]

	},
	SET_DOCUMENT_INVIGILANDO(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_invigilando = [formattedPayload]

	},
	SET_ACTIVE_MAINTENANCE(state, payload) {
		state.document_maintenance_active = payload
	},
}
