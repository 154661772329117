export const getDefaultState = () => {
    return {
        incidences_types: [],
        incidence_type: {
            description : null,
        },
        validation_incidence_type: {
            description : null,
        },
        search:{
            type: null,
        }
    }
}
export default getDefaultState()
