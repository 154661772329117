export const getDefaultState = () => {
    return {
        provinces: [],
        countries: [],
        province_id: null,
        country_id: null,
        province: {

        },
        validation_province: {

        },
    }
}
export default getDefaultState()
