import {updateField} from "vuex-map-fields"
import helper from "@/resources/helpers/helpers"

export default {
	updateField,
	SET_DOCUMENTS_CLIENT_CONTRACT_EE(state, payload) {
		state.documents_client_contract_e_e = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_client_contract_e_e[key] = value

			let dates = ['signature_date','contract_start_date', 'contract_end_date']
			dates.forEach(date => {
				state.documents_client_contract_e_e[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_CLIENT_DELIVERY(state, payload) {
		state.documents_client_delivery = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_client_delivery[key] = value

			let dates = ['signature_date','contract_start_date', 'contract_end_date']
			dates.forEach(date => {
				state.documents_client_delivery[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_TRANSFER_CERTIFICATE(state, payload) {
		state.documents_transfer_certificate = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_transfer_certificate[key] = value

			let dates = ['transfer_date']
			dates.forEach(date => {
				state.documents_transfer_certificate[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_CLIENT_INSTALLATION_RISK(state, payload) {
		state.documents_client_installation_risk = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_client_installation_risk[key] = value

			let dates = ['effective_date']
			dates.forEach(date => {
				state.documents_client_installation_risk[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_CLIENT_EMERGENCY(state, payload) {
		state.documents_client_emergency = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_client_emergency[key] = value

			let dates = ['document_date']
			dates.forEach(date => {
				state.documents_client_emergency[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_CLIENT_CONTRACT_EE(state, payload) {
		state.documents_by_route_client_contract_e_e = payload
	},
	SET_DOCUMENTS_BY_ROUTE_CLIENT_DELIVERY(state, payload) {
		state.documents_by_route_client_delivery = payload
	},
	SET_DOCUMENTS_BY_ROUTE_TRANSFER_CERTIFICATE(state, payload) {
		state.documents_by_route_transfer_certificate = payload
	},
	SET_DOCUMENTS_BY_ROUTE_CLIENT_INSTALLATION_RISK(state, payload) {
		state.documents_by_route_client_installation_risk = payload
	},
	SET_DOCUMENTS_BY_ROUTE_CLIENT_EMERGENCY(state, payload) {
		state.documents_by_route_client_emergency = payload
	},
}
