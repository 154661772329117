export const getDefaultState = () => {
    return {
        masters_installations: [],
        master_installation: {
            id: null,
            installation_code: null,
            active_installation: 0,
            add_safe_list: 0,
            draft: 0,
            company: null,
            client: null,
            proceeding_code: null,
            date_service_partial: null,
            client_cif: null,
            client_phone: null,
            client_email: null,
            client_contact_person: null,
            segment_id: null,
            segment_code: null,
            installation_type_code: null,
            start_date: null,
            end_date: null,
            closing_date: null,
            industry_ownership_id: null,
            period_description: null,
            installation_type_id: null,
            assign_installation_id: null,
            responsible_id: null,
            project_responsible_id: null,
            insured_value: null,
            level_1_id: null,
            level_2_id: null,
            level_3_id: null,
            level_4_id: null,
            report_sol: 0,
            report_solInVigilando: 0,
            report_solAcae: 0,
            client_type_id: null,
            city: null,
            country_id: null,
            postal_code: null,
            province_id: null,
            longitude: null,
            latitude: null,
            figure_id: null,
            address: null,
            territory_opsva_id: null,
            ccpp: 0,
            observations: null,
            or_number: null,
            serial_numbers: []
        },
        validation_master_installation: {
            id: null,
            installation_code: null,
            active: 0,
            add_safe_list: 0,
            draft: 0,
            company: null,
            proceeding_code: null,
            client: null,
            date_service_partial: null,
            client_cif: null,
            client_phone: null,
            client_email: null,
            client_contact_person: null,
            segment_id: null,
            start_date: null,
            end_date: null,
            closing_date: null,
            industry_ownership_id: null,
            period_description: null,
            installation_type_id: null,
            assign_installation_id: null,
            responsible_id: null,
            project_responsible_id: null,
            insured_value: 0,
            level_1_id: null,
            level_2_id: null,
            level_3_id: null,
            level_4_id: null,
            client_type_id: null,
            city: null,
            country_id: null,
            postal_code: null,
            province_id: null,
            longitude: null,
            latitude: null,
            figure_id: null,
            address: null,
            or_number: null
        },

        search: {
            installation_code: null,
            client: null,
            company: null,
            active: null,
            or_number: null,
            serialNumbers: null,
        }
    }
}
export default getDefaultState()