import state from './level2State'
import actions from './level2Actions'
import mutations from './level2Mutations'
import getters from './level2Getters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}