import api from "@/router/api"

export default {
    getAllLevels4({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel4Vhe.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_LEVELS_4', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener los niveles 4',
                })
            })
        })
    },
    searchLevels4({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel4Vhe.search(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_LEVELS_4', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 4',
                    })
                })
        })
    },
    findLevels4({commit}, payload){

        return new Promise((resolve, reject) => {
            api.auxiliariesLevel4Vhe.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_LEVEL_4', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 4',
                    })
                })
        })
    },

    createLevels4({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel4Vhe.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_LEVELS_4', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateLevels4({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel4Vhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteLevels4({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel4Vhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el nivel.',
                    })
                })
        })
    },
}
