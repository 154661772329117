import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./masterInstallationState"
import helper from "@/resources/helpers/helpers"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_MASTERS_INSTALLATIONS(state, payload) {
		state.masters_installations = payload
	},
	SET_MASTER_INSTALLATION(state, payload) {
		state.master_installation = payload

		state.master_installation.segment_code = payload.segment.code
		state.master_installation.installation_type_code = payload.installation_type.code

		let dates = ['start_date', 'end_date']

		dates.forEach(date => {
			state.master_installation[date] = (payload[date]) ? helper.formatDate(payload[date]) : null

		})

	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_master_installation[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_master_installation) {
			state.validation_master_installation[key] = null
		}
	},
}
