export const getDefaultState = () => {
    return {
        children_installations: [],
        child_installation: {
            id: null,
            master_installation_id: null,
            installation_type_id: null,
            vhe_code: null,
            start_date: null,
            end_date: null,
            closing_date: null,
            exploitation_period_description: null,
            access_control_ee: null,
            access_id: null,
            ins_completed_ee: null,
            ins_client_ee: null,
            pe_emergency_id: null,
            report_486_ee: null,
            competition_activities: null,
            vehicles_competition: null,
            work_maintained_competition: null,
            people_traffic_competition: null,
            other_traffic_competition: null,
            active_installation: null,
            replaced_installation: null,
            retired_installation: null,
            legionella_observations: null,
            competition_observations: null,
            n_ped_generic: null,
            has_legionella_grid: null,
            ee_risks_id: null,
            mant_sol_report: null,
            mant_sol_watch: null,
            mant_sol_acae_situation: null,
            mant_sol_perm_work_atx: null,
            mant_sol_legionella: null,
            gas_reception: null,
            gas_reception_connect: null,
            gas_reception_mop: null,
            gas_reception_potency: null,
            termic_rite: null,
            termic_rite_combustible_type: null,
            termic_rite_potency: null,
            termic_rite_demand_tram: null,
            pression_equipment: null,
            pression_equipment_steam: null,
            pression_equipment_hot_water: null,
            pression_equipment_not_danger_fluids: null,
            pression_equipment_danger_fluids: null,
            draft: null,
            serial_number: null,
            level_1_id: null,
            level_2_id: null,
            level_3_id: null,
            level_4_id: null,
            inst_elec_unsubs: null,
            inst_elec_unsubs_pub_place: null,
            inst_elec_unsubs_ind_gen: null,
            inst_elec_unsubs_riks_place: null,
            inst_elec_unsubs_riks_wet: null,
            inst_elec_unsubs_alum_ext: null,
            inst_elec_unsubs_pool: null,
            inst_elec_unsubs_cirurgy: null,
            inst_elec_unsubs_com_zone: null,
            inst_elec_unsubs_rec_veh_elect: null,
            inst_elec_unsubs_rec_ext: null,
            inst_elec_unsubs_rec_mode_4: null,
            inst_elec_inscr_rd_337: null,
            inst_elec_inscr_rd_337v: null,
            inst_elec_inscr_rd_223: null,
            inst_elec_inscr_rd_223v: null,
            gnl_inst_sat: null,
            gnl_inst_sat_vxp: null,
            gnl_serv_veh_gnl: null,
            gnl_gas_recep: null,
            gnl_gas_recep_connect: null,
            gnl_gas_recep_mop: null,
            gnl_gas_recep_potency: null,
            gnl_equip_pression: null,
            gnl_equip_steam: null,
            gnl_equip_hot_water: null,
            gnl_equip_not_danger_fluids: null,
            gnl_equip_danger_fluids: null,
            inst_cond_rite: null,
            inst_cond_rite_pn70: null,
            inst_frig: null,
            inst_frig_level_2: null,
            inst_frig_plus_3000: null,
            inst_frig_3000_300: null,
            inst_frig_300_30: null,
            inst_frig_less_30: null,
            inst_frig_other_refig: null,
            inst_frig_risk: null,
            partial_service: null,
            insured_value: 0,
        },
        validation_child_installation: {
            id: null,
            master_installation_id: null,
            installation_type_id: null,
            vhe_code: null,
            start_date: null,
            end_date: null,
            closing_date: null,
            exploitation_period_description: null,
            access_control_ee: null,
            access_id: null,
            ins_completed_ee: null,
            ins_client_ee: null,
            pe_emergency_id: null,
            report_486_ee: null,
            competition_activities: null,
            vehicles_competition: null,
            work_maintained_competition: null,
            people_traffic_competition: null,
            other_traffic_competition: null,
            active_installation: null,
            legionella_observations: null,
            competition_observations: null,
            n_order_generic: null,
            has_legionella_grid: null,
            ee_risks_id: null,
            mant_sol_report: null,
            mant_sol_watch: null,
            mant_sol_acae_situation: null,
            mant_sol_perm_work_atx: null,
            mant_sol_legionella: null,
            gas_reception: null,
            gas_reception_connect: null,
            gas_reception_mop: null,
            gas_reception_potency: null,
            termic_rite: null,
            termic_rite_combustible_type: null,
            termic_rite_potency: null,
            termic_rite_demand_tram: null,
            pression_equipment: null,
            pression_equipment_steam: null,
            pression_equipment_hot_water: null,
            pression_equipment_not_danger_fluids: null,
            pression_equipment_danger_fluids: null,
            draft: null,
            level_1_id: null,
            level_2_id: null,
            level_3_id: null,
            level_4_id: null,
            inst_elec_unsubs: null,
            inst_elec_unsubs_pub_place: null,
            inst_elec_unsubs_ind_gen: null,
            inst_elec_unsubs_riks_place: null,
            inst_elec_unsubs_riks_wet: null,
            inst_elec_unsubs_alum_ext: null,
            inst_elec_unsubs_pool: null,
            inst_elec_unsubs_cirurgy: null,
            inst_elec_unsubs_com_zone: null,
            inst_elec_unsubs_rec_veh_elect: null,
            inst_elec_unsubs_rec_ext: null,
            inst_elec_unsubs_rec_mode_4: null,
            inst_elec_inscr_rd_337: null,
            inst_elec_inscr_rd_337v: null,
            inst_elec_inscr_rd_223: null,
            inst_elec_inscr_rd_223v: null,
            gnl_inst_sat: null,
            gnl_inst_sat_vxp: null,
            gnl_serv_veh_gnl: null,
            gnl_gas_recep: null,
            gnl_gas_recep_connect: null,
            gnl_gas_recep_mop: null,
            gnl_gas_recep_potency: null,
            gnl_equip_pression: null,
            gnl_equip_steam: null,
            gnl_equip_hot_water: null,
            gnl_equip_not_danger_fluids: null,
            gnl_equip_danger_fluids: null,
            inst_cond_rite: null,
            inst_cond_rite_pn70: null,
            inst_frig: null,
            inst_frig_level_2: null,
            inst_frig_plus_3000: null,
            inst_frig_3000_300: null,
            inst_frig_300_30: null,
            inst_frig_less_30: null,
            inst_frig_other_refig: null,
            inst_frig_risk: null,
            partial_service: null,
            insured_value: null
        },

        search: {
            vhe_code: null,
            'active_installation': null,
            serial_number: null,
            master_installation: {
                company: null,
                client: null,
                or_number: null,
            },
        }
    }
}
export default getDefaultState()
