import state from './orderSubMaintenanceTypeIdState'
import actions from './orderSubMaintenanceTypeIdActions'
import mutations from './orderSubMaintenanceTypeIdMutations'
import getters from './orderSubMaintenanceTypeIdGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
