import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./industryOwnership"

export default {
	updateField,
	SET_INDUSTRY_OWNERSHIPS(state, payload) {
		state.industry_ownerships = payload
	},
	SET_INDUSTRY_OWNERSHIP(state, payload) {
		state.industry_ownership = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_industry_ownership[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_industry_ownership) {
			state.validation_industry_ownership[key] = null
		}
	},
}
