import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./provinceCountry"

export default {
	updateField,
	SET_COUNTRIES(state, payload) {
		//state.provinces_countries = payload

		state.countries  = Object.values(payload).map(item => ({
			id: item.id,
			country_id: item.country_id,
			country: item.country
		}))
	},
	SET_PROVINCES(state, payload) {

		state.provinces  = Object.values(payload).map(item => ({
			id: item.id,
			province_id: item.province_id,
			province: item.province,
			country_id: item.country_id,
		}))
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_province[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_province) {
			state.validation_province[key] = null
		}
	},
}
