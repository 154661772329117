import api from "@/router/api"

export default {
    searchProjectManager({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliaries2ProjectManagerVhe.search(payload)
                .then(response => {
                    resolve(response.data.data.data)
                    commit('SET_PROJECTS_MANAGERS', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los proyectos',
                    })
                })
        })
    },
    findProjectManager({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliaries2ProjectManagerVhe.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_PROJECT_MANAGER', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los proyectos',
                    })
                })
        })
    },

    createProjectManager({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliaries2ProjectManagerVhe.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_PROJECT_MANAGERS', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateProjectManager({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliaries2ProjectManagerVhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteProjectManager({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliaries2ProjectManagerVhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el proyecto.',
                    })
                })
        })
    },
}
