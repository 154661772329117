import {
	VHE_DASHBOARD_METRICS_LIST,
	VHE_DASHBOARD_METRICS_INSTALLATIONS_LIST,
	VHE_DASHBOARD_METRICS_CHILDREN_INSTALLATIONS_LIST,
	VHE_DASHBOARD_METRICS_PLANNED_PLANNING,
	VHE_DASHBOARD_METRICS_MAINTENANCE
} from '../../constants'

export default [
	{
		path: VHE_DASHBOARD_METRICS_LIST,
		name: 'dashboard_metrics_vhe',
		component: () => import('@/views/vhe/dashboard_metrics/list/listView.vue'),
		meta: {
			pageTitle: 'Informe mantenimientos',
			permission: 'list_dashboard_metrics_vhe',
			breadcrumb: [
				{
					text: 'Informe mantenimientos',
					active: true,
				},
			],
		},
	},
	{
		path: VHE_DASHBOARD_METRICS_INSTALLATIONS_LIST,
		name: 'dashboard_metrics_installations_list_vhe',
		component: () => import('@/views/vhe/dashboard_metrics/list/InstallationList/MasterInstallations.vue'),
		meta: {
			pageTitle: 'VHE | Listado detallado de Instalaciones',
			permission: 'list_dashboard_metrics_vhe',
			breadcrumb: [
				{
					text: 'VHE | Listado Instalaciones',
					active: true,
				},
			],
		},
	},
	{
		path: VHE_DASHBOARD_METRICS_CHILDREN_INSTALLATIONS_LIST,
		name: 'dashboard_metrics_children_installations_list_vhe',
		component: () => import('@/views/vhe/dashboard_metrics/list/InstallationList/ChildrenInstallations.vue'),
		meta: {
			pageTitle: 'VHE | Listado detallado de Instalaciones Hijas',
			permission: 'list_dashboard_metrics_vhe',
			breadcrumb: [
				{
					text: 'VHE | Listado Instalaciones Hijas',
					active: true,
				},
			],
		},
	},
	{
		path: VHE_DASHBOARD_METRICS_PLANNED_PLANNING,
		name: 'dashboard_metrics_planned_planning_vhe',
		component: () => import('@/views/vhe/dashboard_metrics/list/plannedPlanningList.vue'),
		meta: {
			pageTitle: 'VHE | Listado Planificación Prevista',
			permission: 'list_dashboard_metrics_vhe',
			breadcrumb: [
				{
					text: 'VHE | Listado Planificación Prevista',
					active: true,
				},
			],
		},
	},
	{
		path: VHE_DASHBOARD_METRICS_MAINTENANCE,
		name: 'dashboard_metrics_maintenance_vhe',
		component: () => import('@/views/vhe/dashboard_metrics/list/maintenanceList.vue'),
		meta: {
			pageTitle: 'VHE | Listado Mantenimientos',
			permission: 'list_dashboard_metrics_vhe',
			breadcrumb: [
				{
					text: 'VHE | Listado Mantenimientos',
					active: true,
				},
			],
		},
	},
]
