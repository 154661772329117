import api from "@/router/api"

export default {
    getAllSubOrderMaintenanceTypeId({commit}, payload){

        return new Promise((resolve, reject) => {
            api.auxiliariesOrderSubMaintenanceTypeVhe.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_ORDERSUBMAINTENANCES', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener el proveedor',
                })
            })
        })
    },
}
