import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./typology"

export default {
	updateField,
	SET_TYPOLOGIES(state, payload) {
		state.typologies = payload
	},
	SET_TYPOLOGY(state, payload) {
		state.typology = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_typology[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_typology) {
			state.validation_typology[key] = null
		}
	},
}
