import state from './projectManagerState'
import actions from './projectManagerActions'
import mutations from './projectManagerMutations'
import getters from './projectManagerGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
