import state from './responsibleManagerState'
import actions from './responsibleManagerActions'
import mutations from './responsibleManagerMutations'
import getters from './responsibleManagerGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
