import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./level3"

export default {
	updateField,
	SET_LEVELS_3(state, payload) {
		state.levels_3 = payload
	},
	SET_LEVEL_3(state, payload) {
		state.level_3 = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_level_3[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_level_3) {
			state.validation_level_3[key] = null
		}
	},
}
