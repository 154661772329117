import state from './clientTypeState'
import actions from './clientTypeActions'
import mutations from './clientTypeMutations'
import getters from './clientTypeGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
