export const getDefaultState = () => {
    return {
        typologies: [],
        typology: {

        },
        validation_typology: {

        },
    }
}
export default getDefaultState()
