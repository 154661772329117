import api from "@/router/api"

export default {
    getAllProducts({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.list(payload)
                .then(response => {
                    resolve(response.data.data)

                    commit('SET_PRODUCTS', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los tipos de instalacion',
                    })
                })
        })
    },
    searchProducts({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.search(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_PRODUCTS', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 2',
                    })
                })
        })
    },
    findProducts({commit}, payload){

        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_PRODUCT', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el tipo de instalación',
                    })
                })
        })
    },
    createProducts({commit}, payload){
        commit('CLEAR_VALIDATION')

        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_PRODUCTS', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateProducts({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteProducts({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el tipo de instalaciones.',
                    })
                })
        })
    },

    getSubProducts({commit}, payload){

        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.listSubProducts(payload.id)
                .then(response => {
                    resolve(response.data.data)
                    //commit('SET_PROVIDERS', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el proveedor',
                    })
                })
        })
    },

    createSubProducts({commit}, payload){
        commit('CLEAR_VALIDATION_SUBPRODUCT')
        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.createSubProducts(payload.subproduct
            )
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_SUBPRODUCTS_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateSubProducts({commit}, payload){
        commit('CLEAR_VALIDATION_SUBPRODUCT')
        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.updateSubProducts(payload.subproduct)
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_SUBPRODUCTS_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteSubProducts({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesProducts.deleteSubProducts(payload.id)
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el subproducto.',
                    })
                })
        })
    },
}
