import {
	GEI_AUXILIAR2_RESPONSIBLES_LIST,
	GEI_AUXILIAR2_RESPONSIBLES_CREATE,
	GEI_AUXILIAR2_RESPONSIBLES_EDIT,
	GEI_AUXILIAR2_PROYECTS_LIST,
	GEI_AUXILIAR2_PROYECTS_CREATE,
	GEI_AUXILIAR2_PROYECTS_EDIT,
} from '../../constants'

export default [
	// RESPONSABLES
	{
		path: GEI_AUXILIAR2_RESPONSIBLES_LIST,
		name: 'list_auxiliar2_responsibles',
		component: () => import('@/views/gei/auxiliars2_responsibles/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de responsables',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado de responsables',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR2_RESPONSIBLES_CREATE,
		name: 'create_auxiliar2_responsibles',
		component: () => import('@/views/gei/auxiliars2_responsibles/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo responsable',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Responsables',
					to: GEI_AUXILIAR2_RESPONSIBLES_LIST,
				},
				{
					text: 'Añadir nuevo responsable',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR2_RESPONSIBLES_EDIT}/:id`,
		name: 'auxiliar2_responsibles_edit',
		component: () => import('@/views/gei/auxiliars2_responsibles/form/formView.vue'),
		meta: {
			pageTitle: 'Responsables',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Responsables',
					to: GEI_AUXILIAR2_RESPONSIBLES_LIST,
				},
				{
					text: 'Editar Responsable',
					active: true,
				},
			],
		},
	},
	// END RESPONSABLES
	// PROYECTOS
	{
		path: GEI_AUXILIAR2_PROYECTS_LIST,
		name: 'list_auxiliar2_proyects',
		component: () => import('@/views/gei/auxiliars2_proyects/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de gestor de proyectos',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado de gestor de proyectos',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR2_PROYECTS_CREATE,
		name: 'create_auxiliar2_proyects',
		component: () => import('@/views/gei/auxiliars2_proyects/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo gestor de proyectos',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Proyectos',
					to: GEI_AUXILIAR2_PROYECTS_LIST,
				},
				{
					text: 'Añadir nuevo gestor de proyectos',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR2_PROYECTS_EDIT}/:id`,
		name: 'auxiliar2_proyects_edit',
		component: () => import('@/views/gei/auxiliars2_proyects/form/formView.vue'),
		meta: {
			pageTitle: 'Editar gestor de proyectos',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Proyectos',
					to: GEI_AUXILIAR2_PROYECTS_LIST,
				},
				{
					text: 'Editar gestor de proyectos',
					active: true,
				},
			],
		},
	},
	// END PROYECTOS
]
