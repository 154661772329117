import { getField } from "vuex-map-fields"

const getters = {
	getField,
	can: state => permission => {
		return state.AppActiveUser.permissions.indexOf(permission) !== -1
	},
	center: state => {
		return state.AppActiveUser.centers.map(function(center) {
			return center.name
		})
	},
	//Comprobar si existe
	checkCenter: state => centers =>  {
		let centersUsers = state.AppActiveUser.centers.map(function(center) {
			return center.name
		})

		return centersUsers.some(r=> centers.includes(r))
	},
	type: state => {
		return state.AppActiveUser.roles.map(function(role) {
			return role.type
		})
	}
}

export default getters