import api from "@/router/api"

export default {
    getAllInstallationTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesInstallationTypes.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_INSTALLATION_TYPES', response.data.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener los tipos de instalacion',
                })
            })
        })
    },
    searchInstallationTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesInstallationTypes.search(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_INSTALLATION_TYPES', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 2',
                    })
                })
        })
    },
    findInstallationTypes({commit}, payload){

        return new Promise((resolve, reject) => {
            api.auxiliariesInstallationTypes.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_INSTALLATION_TYPE', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el tipo de instalación',
                    })
                })
        })
    },
    createInstallationTypes({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesInstallationTypes.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_INSTALLATION_TYPES', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateInstallationTypes({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesInstallationTypes.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteInstallationTypes({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesInstallationTypes.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el tipo de instalaciones.',
                    })
                })
        })
    },
}


