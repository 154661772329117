import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./level1"

export default {
	updateField,
	SET_LEVELS_1(state, payload) {
		state.levels_1 = payload
	},
	SET_LEVEL_1(state, payload) {
		state.level_1 = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_level_1[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_level_1) {
			state.validation_level_1[key] = null
		}
	},
}
