import axios from '@/resources/libs/axios'

export default {
    searchByMasterInstallation(id) {
        return axios.get(`/gei/children-installation/list/master-installation/${id}`)
    },
    find(id, data) {
        return axios.get(`/gei/children-installation/find/${id}`, {params: data})
    },
    listAll(data) {
        return axios.get(`/gei/children-installation/list`, { params: data })
    },
    getAllList(data) {
        return axios.get(`/gei/children-installation/getAllList`, { params: data })
    },
    getByIds(data) {
        return axios.post(`/gei/children-installation/findMultiple`, { params: data })
    },
    update(data) {
        return axios.post(`/gei/children-installation/update`,  data)
    },
    create(data) {
        return axios.post(`/gei/children-installation/create`,  data)
    },
    delete(id) {
        return axios.delete(`/gei/children-installation/delete/${id}`)
    },
}