import api from "@/router/api"

export default {

    getAllDocumentTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesDocumentTypes.list(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_DOCUMENT_TYPES', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los tipos de documentos',
                    })
                })
        })
    },
    searchDocumentTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesDocumentTypes.search(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_DOCUMENT_TYPES', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 2',
                    })
                })
        })
    },
    findDocumentTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesDocumentTypes.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_DOCUMENT_TYPE', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el tipo de documento',
                    })
                })
        })
    },

    createDocumentTypes({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesDocumentTypes.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_DOCUMENT_TYPES', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateDocumentTypes({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesDocumentTypes.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteDocumentTypes({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesDocumentTypes.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el tipo de documento.',
                    })
                })
        })
    },
}
