import api from "@/router/api"
import store from "@/store"

export default {
	getDashboardMetrics({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.dashboardMetrics.getDashboardMetrics(payload)
				.then(response => {

					const { data } = response.data
					commit('SET_DASHBOARD_METRICS', data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener el cuadro de mandos'
					})
				})
		})
	},

	generateInformDocumentExpirationExcel({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.dashboardMetrics.generateInformDocumentExpirationExcel(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({
						message
					})
				})
		})
	},
}
