import axios from '@/resources/libs/axios'

export default {
    getMaintenanceMetrics(data) {
        return axios.get(`/vhe/metrics/getMaintenanceMetrics`, {params: data})
    },
    getMaintenancePlannedMetrics(data) {
        return axios.get(`/vhe/metrics/getMaintenancePlannedMetrics`, {params: data})
    },
    getDashboardMetrics(data) {
        return axios.get(`/vhe/document-expiration-config/calculate-metrics`, {params: data})
    },
    generateInformDocumentExpirationExcel(data) {
        return axios.post(`/vhe/document-expiration-config/export-excel`, data, {responseType: 'blob'})
    },
}