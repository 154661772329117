export const getDefaultState = () => {
    return {
        inspection_made_bys: [],
        inspection_made_by: {
            made_by : null,
        },
        validation_inspection_made_by: {
            made_by : null,
        },
        search:{
            made_by: null,
        }
    }
}
export default getDefaultState()
