import api from "@/router/api"
import store from "@/store"

export default {
    listChildrenInstallationsByMaster({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.childrenInstallations.searchByMasterInstallation(payload.id)
                .then(response => {
                    const { data } = response.data
                    commit('SET_CHILDREN_INSTALLATIONS', data)

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las instalaciones GEI'
                    })
                })
        })
    },
    listAllChildrenInstallations({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.childrenInstallations.listAll(payload)
            .then(response => {
                const { data } = response.data
                commit('SET_CHILDREN_INSTALLATIONS', data.data)

                resolve(data)
            })
            .catch(() => {
                reject({
                    message: 'Error al obtener las instalaciones GEI'
                })
            })
        })
    },
    getAllListChildrenInstallations({ commit }, payload) {
        commit('CLEAR_VALIDATION')

        return new Promise((resolve, reject) => {
            api.childrenInstallations.getAllList(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_CHILDREN_INSTALLATIONS', data)

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las instalaciones GEI'
                    })
                })
        })
    },
    createChildrenInstallation({commit}, payload){
        commit('CLEAR_VALIDATION')

        return new Promise((resolve, reject) => {
            api.childrenInstallations.create(payload)
                .then(response => {

                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateChildrenInstallation({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.childrenInstallations.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    getChildInstallationsByIds({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.childrenInstallations.getByIds( payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteChildrenInstallation({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.childrenInstallations.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar la instalacion.',
                    })
                })
        })
    },
    getChildInstallation({commit}, payload){
        return new Promise((resolve, reject) => {
            api.childrenInstallations.find(payload.id, payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_CHILD_INSTALLATION', response.data.data)

                if(response.data.data.provider_contract_e_e){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_PROVIDER_CONTRACT_EE', response.data.data.provider_contract_e_e)
                }
                if(response.data.data.specific_prevention){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_SPECIFIC_PREVENTION', response.data.data.specific_prevention)
                }
                if(response.data.data.identification_risk_e_e){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_IDENTIFICATION_RISK_EE', response.data.data.identification_risk_e_e)
                }
                if(response.data.data.workplace_e_e){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_WORKPLACE_EE', response.data.data.workplace_e_e)
                }
                if(response.data.data.emergency_plan_e_e){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_EMERGENCY_PLAN_EE', response.data.data.emergency_plan_e_e)
                }
                if(response.data.data.document_a_c_a_e){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_ACAE', response.data.data.document_a_c_a_e)
                }
                if(response.data.data.document_maintenance){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_MAINTENANCE', response.data.data.document_maintenance)
                }
                if(response.data.data.document_manual_project){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_MANUALPROJECT', response.data.data.document_manual_project)
                }
                if(response.data.data.document_order){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_ORDER', response.data.data.document_order)
                }
                if(response.data.data.document_oca){
                    store.commit('childrenInstallationDocuments/SET_DOCUMENTS_OCA', response.data.data.document_oca)
                }

                if(response.data.data.master_installation) {
                    if(response.data.data.master_installation.transfer_certificate){
                        store.commit('masterInstallationDocuments/SET_DOCUMENTS_TRANSFER_CERTIFICATE', response.data.data.master_installation.transfer_certificate)
                    }
                    if(response.data.data.master_installation.client_contract_e_e){
                        store.commit('masterInstallationDocuments/SET_DOCUMENTS_CLIENT_CONTRACT_EE', response.data.data.master_installation.client_contract_e_e)
                    }
                    if(response.data.data.master_installation.client_emergency){
                        store.commit('masterInstallationDocuments/SET_DOCUMENTS_CLIENT_EMERGENCY', response.data.data.master_installation.client_emergency)
                    }

                    store.commit('masterInstallation/SET_MASTER_INSTALLATION', response.data.data.master_installation)
                }

            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener la instalación',
                })
            })
        })
    },
}
