import { USER_LIST, USER_CREATE, USER_EDIT } from '../constants'

export default [
	{
		path: USER_LIST,
		name: 'users_list',
		component: () => import('@/views/users/list/listView.vue'),
		meta: {
			pageTitle: 'Llistat usuaris',
			permission: 'list_users',
			breadcrumb: [
				{
					text: 'Llistat usuaris',
					active: true,
				},
			],
		},
	},
	{
		path: USER_CREATE,
		name: 'users_create',
		component: () => import('@/views/gei/masters_installations/form/formView.vue'),
		meta: {
			pageTitle: 'Crear Usuari',
			permission: 'create_users',
			breadcrumb: [
				{
					text: 'Usuaris',
                    to: USER_LIST,
                },
				{
					text: 'Crear Usuari',
					active: true,
				},
			],
		},
	},
	{
		path: `${USER_EDIT}/:id`,
		name: 'users_edit',
		component: () => import('@/views/gei/masters_installations/form/formView.vue'),
		meta: {
			pageTitle: 'Editar Usuari',
			permission: 'edit_users',
			breadcrumb: [
				{
					text: 'Usuaris',
                    to: USER_LIST,
                },
				{
					text: 'Editar Usuari',
					active: true,
				},
			],
		},
	},
]
