import { VHE_MASTERS_INSTALLATIONS_LIST,VHE_MASTERS_INSTALLATIONS_CREATE,VHE_MASTERS_INSTALLATIONS_EDIT } from '../../constants'

export default [
	{
		path: VHE_MASTERS_INSTALLATIONS_LIST,
		name: 'list_master_installations_vhe',
		component: () => import('@/views/vhe/masters_installations/list/listView.vue'),
		meta: {
			pageTitle: 'Listar instalaciones VHE',
			permission: 'list_vhe_master',
			breadcrumb: [
				{
					text: 'Listado de instalaciones VHE',
					active: true,
				},
			],
		},
	},
	{
		path: VHE_MASTERS_INSTALLATIONS_CREATE,
		name: 'create_master_installation_vhe',
		component: () => import('@/views/vhe/masters_installations/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nueva instalación VHE',
			permission: 'create_vhe_master',
			breadcrumb: [
				{
					text: 'Instalaciones VHE',
					to: VHE_MASTERS_INSTALLATIONS_LIST,
				},
				{
					text: 'Añadir nueva instalación VHE',
					active: true,
				},
			],
		},
	},
	{
		path: `${VHE_MASTERS_INSTALLATIONS_EDIT}/:id`,
		name: 'installations_vhe_edit',
		component: () => import('@/views/vhe/masters_installations/form/formView.vue'),
		meta: {
			pageTitle: 'Mantenimiento VHE',
			permission: 'Dashboard',
			breadcrumb: [
				{
					text: 'Instalaciones',
					to: VHE_MASTERS_INSTALLATIONS_LIST,
				},
				{
					text: 'Mantenimiento VHE',
					active: true,
				},
			],
		},
	},
]
