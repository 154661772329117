import state from './responsibleState'
import actions from './responsibleActions'
import mutations from './responsibleMutations'
import getters from './responsibleGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
