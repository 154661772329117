import api from "@/router/api"

export default {
    searchResponsibleManager({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliaries2ResponsibleManagerVhe.search(payload)
                .then(response => {
                    resolve(response.data.data.data)
                    commit('SET_RESPONSIBLES_MANAGERS', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los responsables',
                    })
                })
        })
    },
    findResponsibleManager({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliaries2ResponsibleManagerVhe.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_RESPONSIBLE_MANAGER', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los responsables',
                    })
                })
        })
    },

    createResponsibleManager({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliaries2ResponsibleManagerVhe.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_RESPONSIBLES_MANAGERS', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateResponsibleManager({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliaries2ResponsibleManagerVhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteResponsibleManager({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliaries2ResponsibleManagerVhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el responsable.',
                    })
                })
        })
    },
}
