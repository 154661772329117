import axios from '@/resources/libs/axios'

export default {
	search(data) {
		return axios.get(`/vhe/master-installation/list`, { params: data })
	},
	find(id, data) {
		return axios.get(`/vhe/master-installation/find/${id}`, {params: data})
	},
	getMasterInstallationsList(data) {
		return axios.get(`/vhe/master-installation/getAllList`, {params: data})
	},
	create(data) {
		return axios.post(`/vhe/master-installation/create`,  data)
	},
	update(data) {
		return axios.post(`/vhe/master-installation/update`,  data)
	},
	updateSerialNumbers(data) {
		return axios.post(`/vhe/master-installation/updateSerialNumbers`,  data)
	},
	delete(id) {
		return axios.delete(`/vhe/master-installation/delete/${id}`)
	},
	deleteMasterInstallationSerialNumber(data) {
		return axios.delete(`/vhe/master-installation/deleteMasterInstallationSerialNumber`,  {params: data})
	},
	getByIds(data) {
		return axios.post(`/vhe/master-installation/findMultiple`, { params: data })
	},
}