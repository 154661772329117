export const getDefaultState = () => {
    return {
        acae_types: [],
        acae_type: {

        },
        validation_acae_type: {

        },
    }
}
export default getDefaultState()
