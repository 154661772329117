export const getDefaultState = () => {
    return {
        validation_dashboard_metrics: [],
     /*   dashboard_metrics_executed_on_date: [],
        dashboard_metrics_executed_out_of_date: [],*/
        dashboard_metrics_executed: [],

        dashboard_metrics: [],
        totals: [],
        installation_list: [],

        showLoading: false,
        tabIndex: 0,
        contentName: 'Listado de instalaciones',

        filter: {
            year: null,
            company: null,
            segment: null,
            installation_type: null,
            installation_active: null,
            responsible: null,
            code_gei: null,
        },
    }
}
export default getDefaultState()
