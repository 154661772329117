import api from "@/router/api"

export default {

    getAllInspectionMadeBy({commit}, payload){

        return new Promise((resolve, reject) => {
            api.auxiliariesInspectionMadeByVhe.list(payload)
                .then(response => {

                    resolve(response.data.data)
                    commit('SET_INSPECTION_MADE_BYS', response.data.data.data)
                })
                .catch(error => {
                    console.log(error)
                    reject({
                        message: 'Ocurrió un problema al obtener los tipos de documentos',
                    })
                })
        })
    },
    searchInspectionMadeBy({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesInspectionMadeByVhe.search(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_INSPECTION_MADE_BYS', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 2',
                    })
                })
        })
    },
    findInspectionMadeBy({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesInspectionMadeByVhe.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_INSPECTION_MADE_BY', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el tipo de documento',
                    })
                })
        })
    },

    createInspectionMadeBy({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesInspectionMadeByVhe.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_INSPECTION_MADE_BYS', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateInspectionMadeBy({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesInspectionMadeByVhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteInspectionMadeBy({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesInspectionMadeByVhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar la inspección.',
                    })
                })
        })
    },
}
