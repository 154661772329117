import {
	DASHBOARD_METRICS_LIST,
	DASHBOARD_METRICS_INSTALLATIONS,
	DASHBOARD_METRICS_INSTALLATIONS_LIST,
	DASHBOARD_METRICS_CHILDREN_INSTALLATIONS_LIST
} from '../../constants'

export default [
	{
		path: DASHBOARD_METRICS_LIST,
		name: 'dashboard_metrics_gei',
		component: () => import('@/views/gei/dashboard_metrics/list/listView.vue'),
		meta: {
			pageTitle: 'Informe mantenimientos',
			permission: 'list_dashboard_metrics',
			breadcrumb: [
				{
					text: 'Informe mantenimientos',
					active: true,
				},
			],
		},
	},
	{
		path: DASHBOARD_METRICS_INSTALLATIONS_LIST,
		name: 'dashboard_metrics_installations_list',
		component: () => import('@/views/gei/dashboard_metrics/list/InstallationList/MasterInstallations.vue'),
		meta: {
			pageTitle: 'GEI | Listado detallado de Instalaciones',
			permission: 'list_dashboard_metrics',
			breadcrumb: [
				{
					text: 'GEI | Listado Instalaciones',
					active: true,
				},
			],
		},
	},
	{
		path: DASHBOARD_METRICS_CHILDREN_INSTALLATIONS_LIST,
		name: 'dashboard_metrics_children_installations_list',
		component: () => import('@/views/gei/dashboard_metrics/list/InstallationList/ChildrenInstallations.vue'),
		meta: {
			pageTitle: 'GEI | Listado detallado de Instalaciones hijas',
			permission: 'list_dashboard_metrics',
			breadcrumb: [
				{
					text: 'GEI | Listado Instalaciones hijas',
					active: true,
				},
			],
		},
	},
]
