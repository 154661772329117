import api from "@/router/api"

export default {
    getAllProjectResponsibles({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProjectResponsibleVhe.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_PROJECT_RESPONSIBLES', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener los responsables de proyectos',
                })
            })
        })
    },
}
