import state from './acaeTypesState'
import actions from './acaeTypesActions'
import mutations from './acaeTypesMutations'
import getters from './acaeTypesGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
