export const getDefaultState = () => {
    return {
        levels_1: [],
        level_1: {

        },
        validation_level_1: {

        },
    }
}
export default getDefaultState()
