export const getDefaultState = () => {
    return {
        proyects: [],
        proyect: {
            name: null,

        },
        validation_proyect: {
            name: null,
        },
        search: {
            name: null,
        },
    }
}
export default getDefaultState()
