import auth from "./modules/auth"
import users from "./modules/users"
import roles from "./modules/roles"

//GEI
import mastersInstallations from "./gei/modules/masterInstallation/mastersInstallations"
import mastersInstallationsDocuments from "./gei/modules/masterInstallationDocuments/mastersInstallationsDocuments"
import childrenInstallations from "./gei/modules/childrenInstallations/childrenInstallations"
import childrenInstallationsDocuments from "./gei/modules/childrenInstallationDocuments/childrenInstallationsDocuments"
import auxiliariesSegments from "./gei/modules/auxiliaries/segments"
import auxiliariesProducts from "./gei/modules/auxiliaries/products"
import auxiliariesProviders from "./gei/modules/auxiliaries/providers"
import auxiliariesOrderMaintenanceType from "./gei/modules/auxiliaries/OrderMaintenanceType"
import auxiliariesOrderSubMaintenanceType from "./gei/modules/auxiliaries/OrderSubMaintenanceType"
import auxiliariesIndustryOwnerships from "./gei/modules/auxiliaries/industryOwnerships"
import auxiliariesInstallationTypes from "./gei/modules/auxiliaries/installationTypes"
import auxiliariesProjectResponsible from "./gei/modules/auxiliaries/projectResponsible"
import auxiliariesResponsible from "./gei/modules/auxiliaries/responsible"
import auxiliariesProyect from "./gei/modules/auxiliaries/proyect"
import auxiliariesLevel1 from "./gei/modules/auxiliaries/level1"
import auxiliariesLevel2 from "./gei/modules/auxiliaries/level2"
import auxiliariesLevel3 from "./gei/modules/auxiliaries/level3"
import auxiliariesLevel4 from "./gei/modules/auxiliaries/level4"
import auxiliariesClientType from "./gei/modules/auxiliaries/clientType"
import auxiliariesInspectionMadeBy from "./gei/modules/auxiliaries/inspectionMadeBy"
import auxiliariesProvincesCountries from "./gei/modules/auxiliaries/provincesCountries"
import auxiliariesOPSVATerritories from "./gei/modules/auxiliaries/opsvaTerritories"
import auxiliariesDocumentTypes from "./gei/modules/auxiliaries/documentTypes"
import auxiliariesAcaeTypes from "./gei/modules/auxiliaries/acaeTypes"
import auxiliariesMaintenanceTaskTypes from "./gei/modules/auxiliaries/maintenanceTaskType"
import auxiliariesCompanies from "./gei/modules/auxiliaries/companies"
import auxiliariesTypologies from "./gei/modules/auxiliaries/typologies"
import dashboardMetrics from "./gei/modules/dashboardMetrics/dashboardMetrics"



//VHE
import mastersInstallationsVhe from "./vhe/modules/masterInstallation/mastersInstallations"
import mastersInstallationsDocumentsVhe from "./vhe/modules/masterInstallationDocuments/mastersInstallationsDocuments"
import childrenInstallationsVhe from "./vhe/modules/childrenInstallations/childrenInstallations"
import childrenInstallationsDocumentsVhe from "./vhe/modules/childrenInstallationDocuments/childrenInstallationsDocuments"
import auxiliariesSegmentsVhe from "./vhe/modules/auxiliaries/segments"
import auxiliariesProductsVhe from "./vhe/modules/auxiliaries/products"
import auxiliariesProvidersVhe from "./vhe/modules/auxiliaries/providers"
import auxiliariesOrderMaintenanceTypeVhe from "./vhe/modules/auxiliaries/OrderMaintenanceType"
import auxiliariesOrderSubMaintenanceTypeVhe from "./vhe/modules/auxiliaries/OrderSubMaintenanceType"
import auxiliariesIndustryOwnershipsVhe from "./vhe/modules/auxiliaries/industryOwnerships"
import auxiliariesInstallationTypesVhe from "./vhe/modules/auxiliaries/installationTypes"
import auxiliariesProjectResponsibleVhe from "./vhe/modules/auxiliaries/projectResponsible"
import auxiliariesResponsibleVhe from "./vhe/modules/auxiliaries/responsible"
import auxiliariesLevel1Vhe from "./vhe/modules/auxiliaries/level1"
import auxiliariesLevel2Vhe from "./vhe/modules/auxiliaries/level2"
import auxiliariesLevel3Vhe from "./vhe/modules/auxiliaries/level3"
import auxiliariesLevel4Vhe from "./vhe/modules/auxiliaries/level4"
import auxiliariesClientTypeVhe from "./vhe/modules/auxiliaries/clientType"
import auxiliariesInspectionMadeByVhe from "./vhe/modules/auxiliaries/inspectionMadeBy"
import auxiliariesProvincesCountriesVhe from "./vhe/modules/auxiliaries/provincesCountries"
import auxiliariesOPSVATerritoriesVhe from "./vhe/modules/auxiliaries/opsvaTerritories"
import auxiliariesDocumentTypesVhe from "./vhe/modules/auxiliaries/documentTypes"
import auxiliariesIncidenceTypesVhe from "./vhe/modules/auxiliaries/incidenceTypes"
import auxiliariesAcaeTypesVhe from "./vhe/modules/auxiliaries/acaeTypes"
import auxiliariesMaintenanceTaskTypesVhe from "./vhe/modules/auxiliaries/maintenanceTaskType"
import auxiliariesCompaniesVhe from "./vhe/modules/auxiliaries/companies"
import auxiliariesTypologiesVhe from "./vhe/modules/auxiliaries/typologies"
import dashboardMetricsVhe from "./vhe/modules/dashboardMetrics/dashboardMetrics"
import auxiliaries2ResponsibleManagerVhe from "./vhe/modules/auxiliaries2/responsibleManager"
import auxiliaries2ProjectManagerVhe from "./vhe/modules/auxiliaries2/projectManager"
import auxiliaries2CorrectiveMaintenanceVhe from "./vhe/modules/auxiliaries2/correctiveMaintenance"

export default {
	auth,
	users,
	roles,

	//GEI
	childrenInstallations,
	childrenInstallationsDocuments,
	mastersInstallations,
	mastersInstallationsDocuments,
	auxiliariesSegments,
	auxiliariesProducts,
	auxiliariesProviders,
	auxiliariesOrderMaintenanceType,
	auxiliariesOrderSubMaintenanceType,
	auxiliariesIndustryOwnerships,
	auxiliariesInstallationTypes,
	auxiliariesProjectResponsible,
	auxiliariesResponsible,
	auxiliariesProyect,
	auxiliariesLevel1,
	auxiliariesLevel2,
	auxiliariesLevel3,
	auxiliariesLevel4,
	auxiliariesClientType,
	auxiliariesProvincesCountries,
	auxiliariesOPSVATerritories,
	auxiliariesDocumentTypes,
	auxiliariesIncidenceTypesVhe,
	auxiliariesInspectionMadeBy,
	auxiliariesAcaeTypes,
	auxiliariesMaintenanceTaskTypes,
	auxiliariesCompanies,
	auxiliariesTypologies,
	dashboardMetrics,

	//VHE
	childrenInstallationsVhe,
	childrenInstallationsDocumentsVhe,
	mastersInstallationsVhe,
	mastersInstallationsDocumentsVhe,
	auxiliariesSegmentsVhe,
	auxiliariesProductsVhe,
	auxiliariesProvidersVhe,
	auxiliariesOrderMaintenanceTypeVhe,
	auxiliariesOrderSubMaintenanceTypeVhe,
	auxiliariesIndustryOwnershipsVhe,
	auxiliariesInstallationTypesVhe,
	auxiliariesProjectResponsibleVhe,
	auxiliariesResponsibleVhe,
	auxiliariesLevel1Vhe,
	auxiliariesLevel2Vhe,
	auxiliariesLevel3Vhe,
	auxiliariesLevel4Vhe,
	auxiliariesClientTypeVhe,
	auxiliariesProvincesCountriesVhe,
	auxiliariesOPSVATerritoriesVhe,
	auxiliariesDocumentTypesVhe,
	auxiliariesInspectionMadeByVhe,
	auxiliariesAcaeTypesVhe,
	auxiliariesMaintenanceTaskTypesVhe,
	auxiliariesCompaniesVhe,
	auxiliariesTypologiesVhe,
	dashboardMetricsVhe,
	auxiliaries2ResponsibleManagerVhe,
	auxiliaries2ProjectManagerVhe,
	auxiliaries2CorrectiveMaintenanceVhe,
}
