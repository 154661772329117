import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./dashboardMetricsState"
import helper from "@/resources/helpers/helpers"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_DASHBOARD_METRICS(state, payload) {
		state.dashboard_metrics = payload.dashboard_metrics
		state.totals = payload.totals
	},
	SET_MAINTENANCE_LIST(state, payload) {
		state.maintenance_list = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_dashboard_metrics[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_dashboard_metrics) {
			state.validation_dashboard_metrics[key] = null
		}
	},
}
