import api from "@/router/api"

export default {

	updateRecordGeneralData({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocumentsVhe.updateRecordGeneralData(payload)
			.then(response => {
				resolve(response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener la instalación',
				})
			})
		})
	},
	createRecordGeneralData({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocumentsVhe.createRecordGeneralData(payload)
			.then(response => {
				resolve(response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener la instalación',
				})
			})
		})
	},
	getDocumentsList({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocumentsVhe.getDocumentsListByRoute(payload.id, payload)
			.then(response => {
				resolve(response.data.data)

				commit('SET_DOCUMENTS_BY_ROUTE_CLIENT_CONTRACT_EE', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_PROVIDER_CONTRACT_EE', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_CLIENT_DELIVERY', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_TRANSFER_CERTIFICATE', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_SPECIFIC_PREVENTION', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_CLIENT_INSTALLATION_RISK', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_CLIENT_EMERGENCY', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_ACAE', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_OCA', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MANUALPROJECT', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INSPECTION', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INSITU', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INVIGILANDO', response.data.data)
				commit('SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_ATEX', response.data.data)

			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener el listado de documentos',
				})
			})
		})
	},
	uploadDocuments({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocumentsVhe.uploadDocuments(payload.id, payload)
			.then(response => {
				resolve(response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener el listado de documentos',
				})
			})
		})
	},
	listAllRecords({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocumentsVhe.listAllRecords(payload.id, payload)
			.then(response => {
				resolve(response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener el listado de documentos',
				})
			})
		})
	},
	deleteRecord({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocumentsVhe.deleteRecord(payload.id, payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el registro.',
				})
			})
		})
	},
	deleteDocument({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocumentsVhe.deleteDocument(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el registro.',
				})
			})
		})
	},

	setInspectionByMaintenance({commit}, payload){
		commit('SET_DOCUMENT_INSPECTION', payload.data)
	},
	setInSituByMaintenance({commit}, payload){
		commit('SET_DOCUMENT_INSITU', payload.data)
	},
	setAtexByMaintenance({commit}, payload){
		commit('SET_DOCUMENT_ATEX', payload.data)
	},
	setInvigilandoByMaintenance({commit}, payload){
		commit('SET_DOCUMENT_INVIGILANDO', payload.data)
	},
	setActiveMaintenance({commit}, payload){
		commit('SET_ACTIVE_MAINTENANCE', payload.data)
	},
}
