import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./responsible"

export default {
	updateField,
	SET_RESPONSIBLES(state, payload) {
		state.responsibles = payload
	},
	SET_RESPONSIBLE(state, payload) {
		state.responsible = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_responsible[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_responsible) {
			state.validation_responsible[key] = null
		}
	},
}
