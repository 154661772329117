export const getDefaultState = () => {
    return {
        installation_types: [],
        installation_type: {
            type: null,
            code: null,
            installation_type_assurance: null,
        },
        validation_installation_type: {
            type: null,
            code: null,
        },
        search:{
            type: null,
            code: null,
        }
    }
}
export default getDefaultState()
