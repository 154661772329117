export const getDefaultState = () => {
    return {
        responsibles: [],
        responsible: {

        },
        validation_responsible: {

        },
    }
}
export default getDefaultState()
