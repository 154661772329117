import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./correctiveMaintenanceState"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_CORRECTIVES_MAINTENANCES(state, payload) {
		state.correctives_maintenances = payload
	},
	SET_CORRECTIVE_MAINTENANCE(state, payload) {
		state.corrective_maintenance = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_corrective_maintenance[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_corrective_maintenance) {
			state.validation_corrective_maintenance[key] = null
		}
	},
}
