import api from "@/router/api"

export default {

	updateRecordGeneralData({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocuments.updateRecordGeneralData(payload)
			.then(response => {
				resolve(response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener la instalación',
				})
			})
		})
	},
	createRecordGeneralData({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocuments.createRecordGeneralData(payload)
			.then(response => {
				resolve(response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener la instalación',
				})
			})
		})
	},
	getDocumentsList({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocuments.getDocumentsListByRoute(payload.id, payload)
			.then(response => {
				resolve(response.data.data)


				commit('SET_DOCUMENTS_BY_ROUTE_CLIENT_CONTRACT_EE', response.data.data)

				commit('SET_DOCUMENTS_BY_ROUTE_CLIENT_DELIVERY', response.data.data)

				commit('SET_DOCUMENTS_BY_ROUTE_TRANSFER_CERTIFICATE', response.data.data)

				commit('SET_DOCUMENTS_BY_ROUTE_CLIENT_INSTALLATION_RISK', response.data.data)

				commit('SET_DOCUMENTS_BY_ROUTE_CLIENT_EMERGENCY', response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener el listado de documentos',
				})
			})
		})
	},
	uploadDocuments({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocuments.uploadDocuments(payload.id, payload)
			.then(response => {
				resolve(response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener el listado de documentos',
				})
			})
		})
	},
	listAllRecords({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocuments.listAllRecords(payload.id, payload)
			.then(response => {
				resolve(response.data.data)
			})
			.catch(error => {
				reject({
					message: 'Ocurrió un problema al obtener el listado de documentos',
				})
			})
		})
	},
	deleteRecord({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocuments.deleteRecord(payload.id, payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el registro.',
				})
			})
		})
	},
	deleteDocument({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallationsDocuments.deleteDocument(payload.id, payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el registro.',
				})
			})
		})
	},
}
