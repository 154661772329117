export const getDefaultState = () => {
    return {
        projects_managers: [],
        project_manager: {
            name: null,
        },
        validation_project_manager: {
            name: null,
        },
        search:{
            name: null,
        }
    }
}
export default getDefaultState()
