import axios from '@/resources/libs/axios'

export default {
	login(email, password, platformId) {
		return axios.post('/login', {email, password, platformId})
	},
	logout() {
		return axios.post('/logout')
	},
	updateUserData(idUser, platform) {
		return axios.post('/infoUser', { idUser, platform })
	},
	setPlatform(platform) {
		return axios.post('/setPlatform', { platform })
	},
}
