import {updateField} from "vuex-map-fields"
import helper from "@/resources/helpers/helpers"

export default {
	updateField,
	SET_DOCUMENTS_CLIENT_CONTRACT_EE(state, payload) {
		state.documents_client_contract_e_e = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_client_contract_e_e[key] = value

			let dates = ['signature_date','contract_start_date', 'contract_end_date']
			dates.forEach(date => {
				state.documents_client_contract_e_e[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_CLIENT_CONTRACT_EE(state, payload) {
		state.documents_by_route_client_contract_e_e = payload
	},

	SET_DOCUMENTS_CLIENT_DELIVERY(state, payload) {
		state.documents_client_delivery = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_client_delivery[key] = value

			let dates = ['signature_date','contract_start_date', 'contract_end_date']
			dates.forEach(date => {
				state.documents_client_delivery[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_CLIENT_DELIVERY(state, payload) {
		state.documents_by_route_client_delivery = payload
	},

	SET_DOCUMENTS_TRANSFER_CERTIFICATE(state, payload) {
		state.documents_transfer_certificate = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_transfer_certificate[key] = value

			let dates = ['transfer_date']
			dates.forEach(date => {
				state.documents_transfer_certificate[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_TRANSFER_CERTIFICATE(state, payload) {
		state.documents_by_route_transfer_certificate = payload
	},

	SET_DOCUMENTS_CLIENT_INSTALLATION_RISK(state, payload) {
		state.documents_client_installation_risk = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_client_installation_risk[key] = value

			let dates = ['effective_date']
			dates.forEach(date => {
				state.documents_client_installation_risk[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_CLIENT_INSTALLATION_RISK(state, payload) {
		state.documents_by_route_client_installation_risk = payload
	},

	SET_DOCUMENTS_CLIENT_EMERGENCY(state, payload) {
		state.documents_client_emergency = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_client_emergency[key] = value

			let dates = ['document_date']
			dates.forEach(date => {
				state.documents_client_emergency[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_CLIENT_EMERGENCY(state, payload) {
		state.documents_by_route_client_emergency = payload
	},

	SET_DOCUMENTS_ACAE(state, payload) {
		state.documents_acae = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_acae[key] = value

			let dates = ['effective_date']
			dates.forEach(date => {
				state.documents_acae[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_ACAE(state, payload) {
		state.documents_by_route_acae = payload
	},

	SET_DOCUMENTS_PROVIDER_CONTRACT_EE(state, payload) {
		state.documents_provider_contract_e_e = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_provider_contract_e_e[key] = value

			let dates = ['contract_date','start_date', 'end_date']
			dates.forEach(date => {
				state.documents_provider_contract_e_e[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_PROVIDER_CONTRACT_EE(state, payload) {
		state.documents_by_route_provider_contract_e_e = payload
	},

	SET_DOCUMENTS_SPECIFIC_PREVENTION(state, payload) {
		state.documents_specific_prevention = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_specific_prevention[key] = value

			let dates = ['received_date','exam_date']
			dates.forEach(date => {
				state.documents_specific_prevention[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_SPECIFIC_PREVENTION(state, payload) {
		state.documents_by_route_specific_prevention = payload
	},

	SET_DOCUMENTS_OCA(state, payload) {
		state.documents_oca = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_oca[key] = value

			let dates = ['effective_date', 'expected_date']
			dates.forEach(date => {
				state.documents_oca[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_OCA(state, payload) {
		state.documents_by_route_oca = payload
	},

	SET_DOCUMENTS_MANUALPROJECT(state, payload) {
		state.documents_manualproject = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_manualproject[key] = value

			let dates = ['document_date']
			dates.forEach(date => {
				state.documents_manualproject[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MANUALPROJECT(state, payload) {
		state.documents_by_route_manualproject = payload
	},

	SET_DOCUMENTS_MAINTENANCE(state, payload) {
		state.documents_maintenance = payload

		Object.entries(payload).forEach(([key, value]) => {

			state.documents_maintenance[key] = value

			let dates = ['effective_date', 'expected_date', 'report_review_date']
			dates.forEach(date => {
				state.documents_maintenance[key][date] = (value[date]) ? helper.formatDate(value[date]) : null
			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE(state, payload) {
		state.documents_by_route_maintenance = payload
	},
	SET_ACTIVE_MAINTENANCE(state, payload) {
		state.document_maintenance_active = payload
	},

	SET_DOCUMENT_ATEX(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_atex = [formattedPayload]
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_ATEX(state, payload) {
		state.documents_by_route_maintenance_atex = payload
	},

	SET_DOCUMENT_INSPECTION(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_inspection = [formattedPayload]

	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INSPECTION(state, payload) {
		state.documents_by_route_maintenance_inspection = payload
	},

	SET_DOCUMENT_INSITU(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_insitu = [formattedPayload]

	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INSITU(state, payload) {
		state.documents_by_route_maintenance_insitu = payload
	},

	SET_DOCUMENT_INVIGILANDO(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_invigilando = [formattedPayload]

	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INVIGILANDO(state, payload) {
		state.documents_by_route_maintenance_invigilando = payload
	},
}
