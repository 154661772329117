import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/users/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/users/list`, {params: data})
	},
	create(data) {
		return axios.post(`/users/create`, data)
	},
	update(data) {
		return axios.post(`/users/update`, data)
	},
	delete(id) {
		return axios.delete(`/users/delete/${id}`)
	},
	listByCenter(id, data) {
		return axios.get(`/users/list/center`, {params: data})
	},
    listAll(data) {
        return axios.get(`/users/list/all`, {params: data})
    },
	educatorsByCenter(id, data) {
		return axios.get(`/users/educators/list/center`, {params: data})
	},
	listDiary(data) {
		return axios.get(`/users/list/diary`, {params: data})
	},
	listNotificationsByUser(id, data) {
		return axios.get(`/notifications/list/unread/${id}`, {params: data})
	},
	markNotificationsAsRead(id, data) {
		return axios.get(`/notifications/user/read/${id}`, {params: data})
	},
	markNotificationAsRead(id, data) {
		return axios.get(`/notifications/user/notification/read/${id}`, {params: data})
	},
}