import state from './inspectionMadeByState'
import actions from './inspectionMadeByActions'
import mutations from './inspectionMadeByMutations'
import getters from './inspectionMadeByGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
