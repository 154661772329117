export const getDefaultState = () => {
    return {
        products: [],
        product: {
            name: null,
            observations: null,
        },
        validation_product: {
            name: null,
        },
        search: {
            name: null,
        },

        validation_subproducts: {
            name: null,
        }
    }
}

export const getDefaultStateSubProducts = () => {
    return {
        subproduct: {
            id: null,
            name: null,
            observations: null,
            product_id: null,
        },
    }
}
export default { ...getDefaultState(), ...getDefaultStateSubProducts() }
