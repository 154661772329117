import api from "@/router/api"

export default {

    getAllIncidenceTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesIncidenceTypesVhe.list(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_INCIDENCE_TYPES', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los tipos de documentos',
                    })
                })
        })
    },
    searchIncidenceTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesIncidenceTypesVhe.search(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_INCIDENCE_TYPES', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 2',
                    })
                })
        })
    },
    findIncidenceTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesIncidenceTypesVhe.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_INCIDENCE_TYPE', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el tipo de documento',
                    })
                })
        })
    },

    createIncidenceTypes({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesIncidenceTypesVhe.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_INCIDENCE_TYPES', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateIncidenceTypes({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesIncidenceTypesVhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteIncidenceTypes({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesIncidenceTypesVhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el tipo de documento.',
                    })
                })
        })
    },
}
