import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./company"

export default {
	updateField,
	SET_COMPANIES(state, payload) {
		state.companies = payload
	},
	SET_COMPANY(state, payload) {
		state.company = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_company[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_company) {
			state.validation_company[key] = null
		}
	},
}
