import state from './level3State'
import actions from './level3Actions'
import mutations from './level3Mutations'
import getters from './level3Getters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
