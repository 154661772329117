export const getDefaultState = () => {
    return {
        companies: [],
        company: {

        },
        validation_company: {

        },
    }
}
export default getDefaultState()
