import {updateField} from "vuex-map-fields"
import {getDefaultState, getDefaultStateSubProducts} from "./productState"
import {getDefaultStateContact} from "@/store/vhe/auxiliaries/providers/providerState"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	RESET_STATE_SUBPRODUCT(state) {
		Object.assign(state, getDefaultStateSubProducts())
	},
	SET_PRODUCTS(state, payload) {
		state.products = payload
	},
	SET_PRODUCT(state, payload) {
		state.product = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_product[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_product) {
			state.validation_product[key] = null
		}
	},
	CLEAR_VALIDATION_SUBPRODUCT(state) {
		for (const key in state.validation_subproducts) {
			state.validation_subproducts[key] = null
		}
	},
	SET_VALIDATION_SUBPRODUCTS_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_subproducts[key] = '* '+payload[key][0]
		}
	},
}
