export const getDefaultState = () => {
    return {
        project_responsibles: [],
        project_responsible: {

        },
        validation_project_responsible: {

        },
    }
}
export default getDefaultState()
