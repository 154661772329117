import state from './opsvaTerritoryState'
import actions from './opsvaTerritoryActions'
import mutations from './opsvaTerritoryMutations'
import getters from './opsvaTerritoryGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
