export const getDefaultState = () => {
    return {
        industry_ownerships: [],
        industry_ownership: {

        },
        validation_industry_ownership: {

        },
    }
}
export default getDefaultState()
