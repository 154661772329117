export const getDefaultState = () => {
    return {
        context: null,

        documents_client_contract_e_e: [],
        documents_by_route_client_contract_e_e: [],

        documents_provider_contract_e_e: [],
        documents_by_route_provider_contract_e_e: [],

        documents_specific_prevention: [],
        documents_by_route_specific_prevention: [],

        documents_client_delivery: [],
        documents_by_route_client_delivery: [],

        documents_transfer_certificate: [],
        documents_by_route_transfer_certificate: [],

        documents_client_installation_risk: [],
        documents_by_route_client_installation_risk: [],

        documents_client_emergency: [],
        documents_by_route_client_emergency: [],

        //ACAE
        documents_acae: [],
        documents_by_route_acae: [],

        //OCA
        documents_oca: [],
        documents_by_route_oca: [],

        //Documentación, proyecto y manuales
        documents_manualproject: [],
        documents_by_route_manualproject: [],

        //Mantenimiento
        document_maintenance_active: null,
        documents_maintenance: [],
        documents_by_route_maintenance: [],

        //Mantenimiento - inspeccion
        documents_maintenance_inspection: [],
        documents_by_route_maintenance_inspection: [],

        //Mantenimiento - insitu
        documents_maintenance_insitu: [],
        documents_by_route_maintenance_insitu: [],

        //Mantenimiento - invigilando
        documents_maintenance_invigilando: [],
        documents_by_route_maintenance_invigilando: [],

        //Mantenimiento - atex
        documents_maintenance_atex: [],
        documents_by_route_maintenance_atex: [],
    }
}
export default getDefaultState()
