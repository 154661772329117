import Vue from 'vue'
import VueRouter from 'vue-router'

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/services/auth/utils'
import { DASHBOARD, LOGIN } from './constants'
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import store from '@/store'

import users from './modules/users'
import roles from './modules/roles'
import systemRoutes from './modules/system'

import mastersInstallations from "./modules/gei/mastersInstallations"
import mastersInstallationsVHE from "./modules/vhe/mastersInstallations"

import auxiliars from "./modules/gei/auxiliars"
import auxiliars2 from "./modules/gei/auxiliars2"
import childrenInstallations from "./modules/gei/childrenInstallations"
import dashboardMetrics from "./modules/gei/dashboardMetrics"

import mastersInstallationsVhe from "./modules/vhe/mastersInstallations"
import auxiliarsVhe from "./modules/vhe/auxiliars"
import auxiliars2Vhe from "./modules/vhe/auxiliars2"
import childrenInstallationsVhe from "./modules/vhe/childrenInstallations"
import dashboardMetricsVhe from "./modules/vhe/dashboardMetrics"

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {x: 0, y: 0}
	},
	routes: [
		{	path: '/', redirect: {name: 'dashboard'} },
		{
			path: DASHBOARD,
			name: 'dashboard',
			component: () => import('@/views/dashboard/Dashboard.vue'),
			meta: {
				permission: 'Dashboard',
			}
		},
		...users,
		...roles,
		...systemRoutes,

		//GEI
		...mastersInstallations,
		...childrenInstallations,
		...dashboardMetrics,
		...auxiliars,
		...auxiliars2,

		//VHE
		...mastersInstallationsVhe,
		...childrenInstallationsVhe,
		...dashboardMetricsVhe,
		...auxiliarsVhe,
		...auxiliars2Vhe,
    ],
})

router.beforeEach((to, _, next) => {
	const isLoggedIn = isUserLoggedIn()

	if (!isLoggedIn && to.fullPath != LOGIN) {
		return next({name: 'auth-login'})
	}

	// Redirect if logged in
	if (to.meta.redirectIfLoggedIn && isLoggedIn) {
		const userData = getUserData()
		next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
	}

	if (to.meta.permission) {
		console.log(to.meta.permission)

		if (!store.getters.can(to.meta.permission)) {
			Vue.$toast({
				component: ToastificationContent,
				position: 'top-right',
				props: {
					title: `No tienes permisos para acceder a esa página.`,
					icon: 'XSquareIcon',
					variant: 'danger',
				},
			})

			router.push({path: DASHBOARD})
		}
	}

	return next()
})

router.afterEach(() => {
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

export default router
