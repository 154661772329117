import state from './correctiveMaintenanceState'
import actions from './correctiveMaintenanceActions'
import mutations from './correctiveMaintenanceMutations'
import getters from './correctiveMaintenanceGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
