import api from "@/router/api"

export default {
    getAllOPSVATerritories({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesOPSVATerritoriesVhe.list(payload)
            .then(response => {
                resolve(response.data.data)

            //    commit('SET_OPSVA_TERRITORIES', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener los territorios OPSVA',
                })
            })
        })
    },
}
