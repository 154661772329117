import state from './childrenInstallationState'
import actions from './childrenInstallationActions'
import mutations from './childrenInstallationMutations'
import getters from './childrenInstallationGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
