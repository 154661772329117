export const getDefaultState = () => {
    return {
        context: null,

        documents_provider_contract_e_e: [],
        documents_by_route_provider_contract_e_e: [],

        //Riesgos
        documents_specific_prevention: [],
        documents_by_route_specific_prevention: [],

        documents_identification_risk_e_e: [],
        documents_by_route_identification_risk_e_e: [],

        documents_workplace_e_e: [],
        documents_by_route_workplace_e_e: [],

        //Medidas emergencia
        documents_emergency_plan_e_e: [],
        documents_by_route_emergency_plan_e_e: [],

        //ACAE
        documents_acae: [],
        documents_by_route_acae: [],

        //Mantenimiento
        document_maintenance_active: null,

        documents_maintenance: [],
        documents_by_route_maintenance: [],

        //Mantenimiento - inspeccion
        documents_maintenance_inspection: [],
        documents_by_route_maintenance_inspection: [],

        //Mantenimiento - insitu
        documents_maintenance_insitu: [],
        documents_by_route_maintenance_insitu: [],

        //Mantenimiento - invigilando
        documents_maintenance_invigilando: [],
        documents_by_route_maintenance_invigilando: [],

        //Mantenimiento - atex
        documents_maintenance_atex: [],
        documents_by_route_maintenance_atex: [],

        //Documentación, proyecto y manuales
        documents_manualproject: [],
        documents_by_route_manualproject: [],

        // Order
        documents_order: [],
        documents_by_route_order: [],

        // OCA
        documents_oca: [],
        documents_by_route_oca: [],
    }
}
export default getDefaultState()
