import api from "@/router/api"

export default {
    getAllLevels3({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel3Vhe.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_LEVELS_3', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener los niveles 3',
                })
            })
        })
    },
    searchLevels3({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel3Vhe.search(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_LEVELS_3', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 3',
                    })
                })
        })
    },
    findLevels3({commit}, payload){

        return new Promise((resolve, reject) => {
            api.auxiliariesLevel3Vhe.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_LEVEL_3', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 3',
                    })
                })
        })
    },

    createLevels3({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel3Vhe.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_LEVELS_3', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateLevels3({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel3Vhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteLevels3({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel3Vhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el nivel.',
                    })
                })
        })
    },
}
