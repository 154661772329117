export const getDefaultState = () => {
    return {
        document_types: [],
        document_type: {
            type : null,
            description : null,
            to_keeper_contract : 0,
            to_manual_project_contract : 0,
            to_emergency : 0,
            to_legionella : 0,
            to_maintenance : 0,
            respect_automatic_credentials : 0,
        },
        validation_document_type: {
            type : null,
            to_keeper_contract : null,
            to_manual_project_contract : null,
            to_emergency : null,
            to_legionella : null,
            to_maintenance : null,
            respect_automatic_credentials : null,
        },
        search:{
            type: null,
        }
    }
}
export default getDefaultState()
