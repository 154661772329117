export const getDefaultState = () => {
    return {
        orderMaintences: [],
        orderMaintence: {

        },
        validation_provider: {

        },
    }
}
export default getDefaultState()
