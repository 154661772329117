export const HOME = '/'
export const DASHBOARD = '/dashboard'

export const LOGIN = '/login'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const PAGE_404 = '/error-404'
export const NOT_AUTHORIZED = '/pages/miscellaneous/not-authorized'
export const UNDER_MAINTENANCE = '/pages/miscellaneous/under-maintenance'
export const ERROR = '/pages/miscellaneous/error'

export const USER_LIST = '/usuarios/listado'
export const USER_CREATE = '/usuarios/crear'
export const USER_EDIT = '/usuarios/editar'

export const ROLES_LIST = '/roles/listado'
export const ROLES_CREATE = '/roles/crear'
export const ROLES_EDIT = '/roles/editar'

//GEI PADRE
export const GEI_MASTERS_INSTALLATIONS_LIST = '/gei/instalaciones/listado'
export const GEI_MASTERS_INSTALLATIONS_CREATE = '/gei/instalaciones/crear'
export const GEI_MASTERS_INSTALLATIONS_EDIT = '/gei/instalaciones/editar'

//GEI HIJA
export const CHILDREN_INSTALLATIONS_LIST = '/gei/instalaciones/hijas/listado'
export const CHILDREN_INSTALLATIONS_CREATE = '/gei/instalaciones/hijas/crear'
export const CHILDREN_INSTALLATIONS_EDIT = '/gei/instalaciones/hijas/editar'

//CUADRO DE MANDOS
export const DASHBOARD_METRICS_LIST = '/gei/cuadro'
export const DASHBOARD_METRICS_INSTALLATIONS = '/gei/cuadro/instalaciones'

//----------------------------------------------------------------------------------
//CUADRO DE MANDOS
export const DASHBOARD_METRICS_INSTALLATIONS_LIST = '/gei/cuadro/listado/instalaciones'
export const DASHBOARD_METRICS_CHILDREN_INSTALLATIONS_LIST = '/gei/cuadro/listado/instalaciones-hijas'

// AUXILIARES
export const GEI_AUXILIAR_PROVIDERS_LIST = '/gei/auxiliares/proveedores/listado'
export const GEI_AUXILIAR_PROVIDERS_CREATE = '/gei/auxiliares/proveedores/crear'
export const GEI_AUXILIAR_PROVIDERS_EDIT = '/gei/auxiliares/proveedores/editar'

export const GEI_AUXILIAR_CLIENTTYPES_LIST = '/gei/auxiliares/tipos-de-clientes/listado'
export const GEI_AUXILIAR_CLIENTTYPES_CREATE = '/gei/auxiliares/tipos-de-clientes/crear'
export const GEI_AUXILIAR_CLIENTTYPES_EDIT = '/gei/auxiliares/tipos-de-clientes/editar'

export const GEI_AUXILIAR_INSTALLATIONTYPES_LIST = '/gei/auxiliares/tipos-de-instalaciones/listado'
export const GEI_AUXILIAR_INSTALLATIONTYPES_CREATE = '/gei/auxiliares/tipos-de-instalaciones/crear'
export const GEI_AUXILIAR_INSTALLATIONTYPES_EDIT = '/gei/auxiliares/tipos-de-instalaciones/editar'

export const GEI_AUXILIAR_PRODUCTS_LIST = '/gei/auxiliares/productos/listado'
export const GEI_AUXILIAR_PRODUCTS_CREATE = '/gei/auxiliares/productos/crear'
export const GEI_AUXILIAR_PRODUCTS_EDIT = '/gei/auxiliares/productos/editar'

export const GEI_AUXILIAR_DOCUMENTTYPES_LIST = '/gei/auxiliares/tipos-de-documentos/listado'
export const GEI_AUXILIAR_DOCUMENTTYPES_CREATE = '/gei/auxiliares/tipos-de-documentos/crear'
export const GEI_AUXILIAR_DOCUMENTTYPES_EDIT = '/gei/auxiliares/tipos-de-documentos/editar'

export const GEI_AUXILIAR_INSPECTIONMADEBY_LIST = '/gei/auxiliares/inspecciones-realizadas/listado'
export const GEI_AUXILIAR_INSPECTIONMADEBY_CREATE = '/gei/auxiliares/inspecciones-realizadas/crear'
export const GEI_AUXILIAR_INSPECTIONMADEBY_EDIT = '/gei/auxiliares/inspecciones-realizadas/editar'

export const GEI_AUXILIAR_LEVEL2_LIST = '/gei/auxiliares/nivel2/listado'
export const GEI_AUXILIAR_LEVEL2_CREATE = '/gei/auxiliares/nivel2/crear'
export const GEI_AUXILIAR_LEVEL2_EDIT = '/gei/auxiliares/nivel2/editar'

export const GEI_AUXILIAR2_RESPONSIBLES_LIST = '/gei/auxiliares2/responsables/listado'
export const GEI_AUXILIAR2_RESPONSIBLES_CREATE = '/gei/auxiliares2/responsables/crear'
export const GEI_AUXILIAR2_RESPONSIBLES_EDIT = '/gei/auxiliares2/responsables/editar'

export const GEI_AUXILIAR2_PROYECTS_LIST = '/gei/auxiliares2/proyectos/listado'
export const GEI_AUXILIAR2_PROYECTS_CREATE = '/gei/auxiliares2/proyectos/crear'
export const GEI_AUXILIAR2_PROYECTS_EDIT = '/gei/auxiliares2/proyectos/editar'
//--------------------------------------------------------------------------------------------//
//VHE
//VHE PADRE
export const VHE_MASTERS_INSTALLATIONS_LIST = '/vhe/instalaciones/listado'
export const VHE_MASTERS_INSTALLATIONS_CREATE = '/vhe/instalaciones/crear'
export const VHE_MASTERS_INSTALLATIONS_EDIT = '/vhe/instalaciones/editar'

//VHE HIJA
export const VHE_CHILDREN_INSTALLATIONS_LIST = '/vhe/instalaciones/hijas/listado'
export const VHE_CHILDREN_INSTALLATIONS_CREATE = '/vhe/instalaciones/hijas/crear'
export const VHE_CHILDREN_INSTALLATIONS_EDIT = '/vhe/instalaciones/hijas/editar'

//CUADRO DE MANDOS
export const VHE_DASHBOARD_METRICS_LIST = '/vhe/cuadro'
export const VHE_DASHBOARD_METRICS_INSTALLATIONS_LIST = '/vhe/cuadro/listado/instalaciones'
export const VHE_DASHBOARD_METRICS_CHILDREN_INSTALLATIONS_LIST = '/vhe/cuadro/listado/instalaciones-hijas'
export const VHE_DASHBOARD_METRICS_PLANNED_PLANNING = '/vhe/cuadro/listado/planificacion-prevista'
export const VHE_DASHBOARD_METRICS_MAINTENANCE = '/vhe/cuadro/listado/mantenimientos'

// AUXILIARES
export const VHE_AUXILIAR_PROVIDERS_LIST = '/vhe/auxiliares/proveedores/listado'
export const VHE_AUXILIAR_PROVIDERS_CREATE = '/vhe/auxiliares/proveedores/crear'
export const VHE_AUXILIAR_PROVIDERS_EDIT = '/vhe/auxiliares/proveedores/editar'

export const VHE_AUXILIAR_CLIENTTYPES_LIST = '/vhe/auxiliares/tipos-de-clientes/listado'
export const VHE_AUXILIAR_CLIENTTYPES_CREATE = '/vhe/auxiliares/tipos-de-clientes/crear'
export const VHE_AUXILIAR_CLIENTTYPES_EDIT = '/vhe/auxiliares/tipos-de-clientes/editar'

export const VHE_AUXILIAR_INSTALLATIONTYPES_LIST = '/vhe/auxiliares/tipos-de-instalaciones/listado'
export const VHE_AUXILIAR_INSTALLATIONTYPES_CREATE = '/vhe/auxiliares/tipos-de-instalaciones/crear'
export const VHE_AUXILIAR_INSTALLATIONTYPES_EDIT = '/vhe/auxiliares/tipos-de-instalaciones/editar'

export const VHE_AUXILIAR_PRODUCTS_LIST = '/vhe/auxiliares/productos/listado'
export const VHE_AUXILIAR_PRODUCTS_CREATE = '/vhe/auxiliares/productos/crear'
export const VHE_AUXILIAR_PRODUCTS_EDIT = '/vhe/auxiliares/productos/editar'

export const VHE_AUXILIAR_DOCUMENTTYPES_LIST = '/vhe/auxiliares/tipos-de-documentos/listado'
export const VHE_AUXILIAR_DOCUMENTTYPES_CREATE = '/vhe/auxiliares/tipos-de-documentos/crear'
export const VHE_AUXILIAR_DOCUMENTTYPES_EDIT = '/vhe/auxiliares/tipos-de-documentos/editar'

export const VHE_AUXILIAR_INSPECTIONMADEBY_LIST = '/vhe/auxiliares/inspecciones-realizadas/listado'
export const VHE_AUXILIAR_INSPECTIONMADEBY_CREATE = '/vhe/auxiliares/inspecciones-realizadas/crear'
export const VHE_AUXILIAR_INSPECTIONMADEBY_EDIT = '/vhe/auxiliares/inspecciones-realizadas/editar'

export const VHE_AUXILIAR_LEVEL1_LIST = '/vhe/auxiliares/nivel1/listado'
export const VHE_AUXILIAR_LEVEL1_CREATE = '/vhe/auxiliares/nivel1/crear'
export const VHE_AUXILIAR_LEVEL1_EDIT = '/vhe/auxiliares/nivel1/editar'
export const VHE_AUXILIAR_LEVEL2_LIST = '/vhe/auxiliares/nivel2/listado'
export const VHE_AUXILIAR_LEVEL2_CREATE = '/vhe/auxiliares/nivel2/crear'
export const VHE_AUXILIAR_LEVEL2_EDIT = '/vhe/auxiliares/nivel2/editar'

export const VHE_AUXILIAR_LEVEL3_LIST = '/vhe/auxiliares/nivel3/listado'
export const VHE_AUXILIAR_LEVEL3_CREATE = '/vhe/auxiliares/nivel3/crear'
export const VHE_AUXILIAR_LEVEL3_EDIT = '/vhe/auxiliares/nivel3/editar'

export const VHE_AUXILIAR_LEVEL4_LIST = '/vhe/auxiliares/nivel4/listado'
export const VHE_AUXILIAR_LEVEL4_CREATE = '/vhe/auxiliares/nivel4/crear'
export const VHE_AUXILIAR_LEVEL4_EDIT = '/vhe/auxiliares/nivel4/editar'

// AUXILIARS 2

export const VHE_RESPONSIBLE_MANAGER_LIST = '/vhe/auxiliares2/gestor-responsable/listado'
export const VHE_RESPONSIBLE_MANAGER_CREATE = '/vhe/auxiliares2/gestor-responsable/crear'
export const VHE_RESPONSIBLE_MANAGER_EDIT = '/vhe/auxiliares2/gestor-responsable/editar'

export const VHE_PROJECT_MANAGER_LIST = '/vhe/auxiliares2/gestor-proyecto/listado'
export const VHE_PROJECT_MANAGER_CREATE = '/vhe/auxiliares2/gestor-proyecto/crear'
export const VHE_PROJECT_MANAGER_EDIT = '/vhe/auxiliares2/gestor-proyecto/editar'

export const VHE_CORRECTIVE_MAINTENANCE_INCIDENT_LIST = '/vhe/auxiliares2/incidencias-mantenimiento-correctivo/listado'
export const VHE_CORRECTIVE_MAINTENANCE_INCIDENT_CREATE = '/vhe/auxiliares2/incidencias-mantenimiento-correctivo/crear'
export const VHE_CORRECTIVE_MAINTENANCE_INCIDENT_EDIT = '/vhe/auxiliares2/incidencias-mantenimiento-correctivo/editar'