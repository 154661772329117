import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./orderMaintenanceTypeId"

export default {
	updateField,
	SET_ORDERMAINTENANCES(state, payload) {
		state.orderMaintences = payload

	},
	SET_ORDERMAINTENANCE(state, payload) {
		state.orderMaintences = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_provider[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_provider) {
			state.validation_provider[key] = null
		}
	},
}
