import api from "@/router/api"
import store from "@/store"

export default {
	searchMasterInstallations({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallations.search(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_MASTERS_INSTALLATIONS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener las instalaciones GEI'
					})
				})
		})
	},
	getMasterInstallation({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallations.find(payload.id, payload)
				.then(response => {
					resolve(response.data.data)


					commit('SET_MASTER_INSTALLATION', response.data.data)

					if(response.data.data.client_contract_e_e){
						store.commit('masterInstallationDocuments/SET_DOCUMENTS_CLIENT_CONTRACT_EE', response.data.data.client_contract_e_e)
					}

					if(response.data.data.client_delivery){
						store.commit('masterInstallationDocuments/SET_DOCUMENTS_CLIENT_DELIVERY', response.data.data.client_delivery)
					}

					if(response.data.data.transfer_certificate){
						store.commit('masterInstallationDocuments/SET_DOCUMENTS_TRANSFER_CERTIFICATE', response.data.data.transfer_certificate)
					}

					if(response.data.data.client_installation_risk){
						store.commit('masterInstallationDocuments/SET_DOCUMENTS_CLIENT_INSTALLATION_RISK', response.data.data.client_installation_risk)
					}

					if(response.data.data.client_emergency){
						store.commit('masterInstallationDocuments/SET_DOCUMENTS_CLIENT_EMERGENCY', response.data.data.client_emergency)
					}
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener la instalación',
					})
				})
		})
	},
	createMasterInstallation({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.mastersInstallations.create(payload)
			.then(response => {
				const {data} = response.data
				commit('SET_MASTER_INSTALLATION', response.data.data)
				resolve(data)
			})
			.catch(error => {
				const { data, status} = error.response
				const { message: title } = data

				if(status === 422){
					commit('SET_VALIDATION_ERRORS', data.errors)
				}
				reject({title, status})
			})
		})
	},
	updateMasterInstallation({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.mastersInstallations.update(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(error => {
				const { data, status} = error.response
				const { message: title } = data

				if(status === 422){
					commit('SET_VALIDATION_ERRORS', data.errors)
				}
				reject({title, status})
			})
		})
	},
	deleteMasterInstallation({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallations.delete(payload.id)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la instalacion.',
				})
			})
		})
	},
	getMasterInstallationsList({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.mastersInstallations.getMasterInstallationsList(payload)
			.then(response => {
				const {data} = response.data
				commit('SET_MASTERS_INSTALLATIONS', data)
				resolve(data)
			})
			.catch(error => {
				const { data, status} = error.response
				const { message: title } = data

				if(status === 422){
					commit('SET_VALIDATION_ERRORS', data.errors)
				}
				reject({title, status})
			})
		})
	},
}
