import {
	VHE_RESPONSIBLE_MANAGER_LIST,
	VHE_RESPONSIBLE_MANAGER_CREATE,
	VHE_RESPONSIBLE_MANAGER_EDIT,
	VHE_PROJECT_MANAGER_LIST,
	VHE_PROJECT_MANAGER_CREATE,
	VHE_PROJECT_MANAGER_EDIT,
	VHE_CORRECTIVE_MAINTENANCE_INCIDENT_LIST,
	VHE_CORRECTIVE_MAINTENANCE_INCIDENT_CREATE,
	VHE_CORRECTIVE_MAINTENANCE_INCIDENT_EDIT,
} from '../../constants'

export default [

	// 	GESTOR RESPONSABLE
	{
		path: VHE_RESPONSIBLE_MANAGER_LIST,
		name: 'auxiliar2_list_responsible_manager_vhe',
		component: () => import('@/views/vhe/auxiliars2_responsible_manager/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de responsables',
			permission: 'list_vhe_providers',
			breadcrumb: [
				{
					text: 'Listado de responsables',
					active: true,
				},
			],
		},
	},
	{
		path: VHE_RESPONSIBLE_MANAGER_CREATE,
		name: 'auxiliar2_create_responsible_manager_vhe',
		component: () => import('@/views/vhe/auxiliars2_responsible_manager/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo responsable',
			permission: 'create_vhe_providers',
			breadcrumb: [
				{
					text: 'GESTOR RESPONSABLE',
					to: VHE_RESPONSIBLE_MANAGER_LIST,
				},
				{
					text: 'Añadir nuevo responsable',
					active: true,
				},
			],
		},
	},
	{
		path: `${VHE_RESPONSIBLE_MANAGER_EDIT}/:id`,
		name: 'auxiliar2_responsible_manager_edit_vhe',
		component: () => import('@/views/vhe/auxiliars2_responsible_manager/form/formView.vue'),
		meta: {
			pageTitle: 'Editar responsable',
			permission: 'edit_vhe_providers',
			breadcrumb: [
				{
					text: 'GESTOR RESPONSABLE',
					to: VHE_RESPONSIBLE_MANAGER_LIST,
				},
				{
					text: 'Editar responsable',
					active: true,
				},
			],
		},
	},
	// END GESTOR RESPONSABLE

	// 	GESTOR DE PROYECTOS
	{
		path: VHE_PROJECT_MANAGER_LIST,
		name: 'auxiliar2_list_project_manager_vhe',
		component: () => import('@/views/vhe/auxiliars2_project_manager/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de proyectos',
			permission: 'list_vhe_providers',
			breadcrumb: [
				{
					text: 'Listado de proyectos',
					active: true,
				},
			],
		},
	},
	{
		path: VHE_PROJECT_MANAGER_CREATE,
		name: 'auxiliar2_create_project_manager_vhe',
		component: () => import('@/views/vhe/auxiliars2_project_manager/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo proyecto',
			permission: 'create_vhe_providers',
			breadcrumb: [
				{
					text: 'GESTOR DE PROYECTOS',
					to: VHE_PROJECT_MANAGER_LIST,
				},
				{
					text: 'Añadir nuevo proyecto',
					active: true,
				},
			],
		},
	},
	{
		path: `${VHE_PROJECT_MANAGER_EDIT}/:id`,
		name: 'auxiliar2_project_manager_edit_vhe',
		component: () => import('@/views/vhe/auxiliars2_project_manager/form/formView.vue'),
		meta: {
			pageTitle: 'Editar proyecto',
			permission: 'edit_vhe_providers',
			breadcrumb: [
				{
					text: 'GESTOR DE PORYECTOS',
					to: VHE_PROJECT_MANAGER_LIST,
				},
				{
					text: 'Editar proyecto',
					active: true,
				},
			],
		},
	},
	// END GESTOR DE PROYECTOS

	// 	INCIDENCIAS MANTENIMIENTO CORRECTIVO
	{
		path: VHE_CORRECTIVE_MAINTENANCE_INCIDENT_LIST,
		name: 'auxiliar2_list_corrective_maintenance_incident_vhe',
		component: () => import('@/views/vhe/auxiliars2_corrective_maintenance_incident/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de incidencias',
			permission: 'list_vhe_providers',
			breadcrumb: [
				{
					text: 'Listado de incidencias',
					active: true,
				},
			],
		},
	},
	{
		path: VHE_CORRECTIVE_MAINTENANCE_INCIDENT_CREATE,
		name: 'auxiliar2_create_corrective_maintenance_incident_vhe',
		component: () => import('@/views/vhe/auxiliars2_corrective_maintenance_incident/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nueva incidencia',
			permission: 'create_vhe_providers',
			breadcrumb: [
				{
					text: 'GESTOR DE INCIDENCIAS',
					to: VHE_CORRECTIVE_MAINTENANCE_INCIDENT_LIST,
				},
				{
					text: 'Añadir nueva incidencia',
					active: true,
				},
			],
		},
	},
	{
		path: `${VHE_CORRECTIVE_MAINTENANCE_INCIDENT_EDIT}/:id`,
		name: 'auxiliar2_corrective_maintenance_incident_edit_vhe',
		component: () => import('@/views/vhe/auxiliars2_corrective_maintenance_incident/form/formView.vue'),
		meta: {
			pageTitle: 'Editar incidencia',
			permission: 'edit_vhe_providers',
			breadcrumb: [
				{
					text: 'GESTOR DE INCIDENCIAS',
					to: VHE_CORRECTIVE_MAINTENANCE_INCIDENT_LIST,
				},
				{
					text: 'Editar incidencia',
					active: true,
				},
			],
		},
	},
	// END INCIDENCIAS MANTENIMIENTO CORRECTIVO
]
