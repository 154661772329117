import axios from '@/resources/libs/axios'
import {GEI_AUXILIAR_PROVIDERS_LIST} from "@/router/web/constants"

export default {
    list(data) {
        return axios.get(`/vhe/auxiliaries/providers/list/all`,{ params: data })
    },
    search(data) {
        return axios.get(`/vhe/auxiliaries/providers/list`,{ params: data })
    },
    find(data) {
        return axios.get(`/vhe/auxiliaries/providers/find/${data.id}`,{ params: data })
    },
    create(data) {
        return axios.post(`/vhe/auxiliaries/providers/create`,  data)
    },
    update(data) {
        return axios.post(`/vhe/auxiliaries/providers/update`,  data)
    },
    delete(id) {
        return axios.delete(`/vhe/auxiliaries/providers/delete/${id}`)
    },
    listContactsProviders(id, data) {
        return axios.get(`/vhe/auxiliaries/providers/contacts/list/${id}`, { params: data })
    },
    createContact(data){
        return axios.post(`/vhe/auxiliaries/providers/contacts/create`,  data)
    },
    updateContact(data){
        return axios.post(`/vhe/auxiliaries/providers/contacts/update`,  data)
    },
    deleteContact(id) {
        return axios.delete(`/vhe/auxiliaries/providers/contacts/delete/${id}`)
    },
}
