import api from "@/router/api"
import store from "@/store"

export default {
	searchMasterInstallations({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.search(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_MASTERS_INSTALLATIONS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener las instalaciones GEI'
					})
				})
		})
	},
	getMasterInstallation({commit}, payload){
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.find(payload.id, payload)
				.then(response => {
					resolve(response.data.data)

					commit('SET_MASTER_INSTALLATION', response.data.data)

					if(response.data.data.client_contract_e_e){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_CLIENT_CONTRACT_EE', response.data.data.client_contract_e_e)
					}
					if(response.data.data.client_delivery){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_CLIENT_DELIVERY', response.data.data.client_delivery)
					}
					if(response.data.data.transfer_certificate){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_TRANSFER_CERTIFICATE', response.data.data.transfer_certificate)
					}
					if(response.data.data.client_installation_risk){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_CLIENT_INSTALLATION_RISK', response.data.data.client_installation_risk)
					}
					if(response.data.data.client_emergency){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_CLIENT_EMERGENCY', response.data.data.client_emergency)
					}
					if(response.data.data.document_a_c_a_e){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_ACAE', response.data.data.document_a_c_a_e)
					}
					if(response.data.data.specific_prevention) {
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_SPECIFIC_PREVENTION', response.data.data.specific_prevention)
					}
					if(response.data.data.provider_contract_e_e) {
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_PROVIDER_CONTRACT_EE', response.data.data.provider_contract_e_e)
					}
					if(response.data.data.document_oca){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_OCA', response.data.data.document_oca)
					}
					if(response.data.data.document_manual_project){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_MANUALPROJECT', response.data.data.document_manual_project)
					}
					if(response.data.data.document_maintenance){
						store.commit('masterInstallationDocumentsVhe/SET_DOCUMENTS_MAINTENANCE', response.data.data.document_maintenance)
					}
					if(response.data.data.serial_numbers){
						store.commit('masterInstallationVhe/SET_SERIAL_NUMBERS', response.data.data.serial_numbers)
					}

				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener la instalación',
					})
				})
		})
	},
	createMasterInstallation({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.create(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_MASTER_INSTALLATION', response.data.data)
					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateMasterInstallation({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.update(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateMasterInstallationSerialNumbers({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.updateSerialNumbers(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteMasterInstallation({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.delete(payload.id)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar la instalacion.',
					})
				})
		})
	},
	deleteMasterInstallationSerialNumber({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.deleteMasterInstallationSerialNumber(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar la instalacion.',
					})
				})
		})
	},
	getMasterInstallationsList({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.getMasterInstallationsList(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_MASTERS_INSTALLATIONS', data)
					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	getChildInstallationsByIds({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.mastersInstallationsVhe.getByIds( payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
}