import state from './installationTypeState'
import actions from './installationTypeActions'
import mutations from './installationTypeMutations'
import getters from './installationTypeGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
