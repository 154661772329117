import state from './orderMaintenanceTypeIdState'
import actions from './orderMaintenanceTypeIdActions'
import mutations from './orderMaintenanceTypeIdMutations'
import getters from './orderMaintenanceTypeIdGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
