import axios from '@/resources/libs/axios'

export default {
    search(data) {
        return axios.get(`/vhe/auxiliaries/inspection-made-by/list`, { params: data })
    },
    find(data) {
        return axios.get(`/vhe/auxiliaries/inspection-made-by/find/${data.id}`,{ params: data })
    },
    create(data) {
        return axios.post(`/vhe/auxiliaries/inspection-made-by/create`,  data)
    },
    update(data) {
        return axios.post(`/vhe/auxiliaries/inspection-made-by/update`,  data)
    },
    delete(id) {
        return axios.delete(`/vhe/auxiliaries/inspection-made-by/delete/${id}`)
    },
}