import state from './level4State'
import actions from './level4Actions'
import mutations from './level4Mutations'
import getters from './level4Getters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
