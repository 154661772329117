import { GEI_MASTERS_INSTALLATIONS_LIST,GEI_MASTERS_INSTALLATIONS_CREATE,GEI_MASTERS_INSTALLATIONS_EDIT } from '../../constants'

export default [
	{
		path: GEI_MASTERS_INSTALLATIONS_LIST,
		name: 'list_master_installations',
		component: () => import('@/views/gei/masters_installations/list/listView.vue'),
		meta: {
			pageTitle: 'Listar instalaciones GEI',
			permission: 'list_gei_master',
			breadcrumb: [
				{
					text: 'Listado de instalaciones GEI',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_MASTERS_INSTALLATIONS_CREATE,
		name: 'create_master_installation',
		component: () => import('@/views/gei/masters_installations/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nueva instalación GEI',
			permission: 'create_gei_master',
			breadcrumb: [
				{
					text: 'Instalaciones GEI',
                    to: GEI_MASTERS_INSTALLATIONS_LIST,
                },
				{
					text: 'Añadir nueva instalación GEI',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_MASTERS_INSTALLATIONS_EDIT}/:id`,
		name: 'installations_gei_edit',
		component: () => import('@/views/gei/masters_installations/form/formView.vue'),
		meta: {
			pageTitle: 'Mantenimiento GEI',
			permission: 'Dashboard',
			breadcrumb: [
				{
					text: 'Instalaciones',
                    to: GEI_MASTERS_INSTALLATIONS_LIST,
                },
				{
					text: 'Mantenimiento GEI',
					active: true,
				},
			],
		},
	},
]
