import state from './providerState'
import actions from './providerActions'
import mutations from './providerMutations'
import getters from './providerGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
