import state from './segmentState'
import actions from './segmentActions'
import mutations from './segmentMutations'
import getters from './segmentGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
