import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./segment"

export default {
	updateField,
	SET_SEGMENTS(state, payload) {
		state.segments = payload
	},
	SET_SEGMENT(state, payload) {
		state.segment = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_segment[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_segment) {
			state.validation_segment[key] = null
		}
	},
}
