import axios from '@/resources/libs/axios'

export default {
    list(data) {
        return axios.get(`/gei/auxiliaries2/proyects/list`, { params: data })
    },
    find(data) {
        return axios.get(`/gei/auxiliaries2/proyects/find/${data.id}`,{ params: data })
    },
    create(data) {
        return axios.post(`/gei/auxiliaries2/proyects/create`,  data)
    },
    update(data) {
        return axios.post(`/gei/auxiliaries2/proyects/update`,  data)
    },
    delete(id) {
        return axios.delete(`/gei/auxiliaries2/proyects/delete/${id}`)
    },
}