export const getDefaultState = () => {
    return {
        responsibles_managers: [],
        responsible_manager: {
            name: null,
        },
        validation_responsible_manager: {
            name: null,
        },
        search:{
            name: null,
        }
    }
}
export default getDefaultState()
