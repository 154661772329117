export const getDefaultState = () => {
    return {
        levels_4: [],
        level_4: {

        },
        validation_level_4: {

        },
    }
}
export default getDefaultState()
