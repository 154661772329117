import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./maintenanceTaskType"

export default {
	updateField,
	SET_MAINTENANCE_TASK_TYPES(state, payload) {
		state.maintenance_task_types = payload
	},
	SET_MAINTENANCE_TASK_TYPE(state, payload) {
		state.maintenance_task_type = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_maintenance_task_type[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_maintenance_task_type) {
			state.validation_maintenance_task_type[key] = null
		}
	},
}
