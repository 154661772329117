import state from './industryOwnershipState'
import actions from './industryOwnershipActions'
import mutations from './industryOwnershipMutations'
import getters from './industryOwnershipGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
