export const getDefaultState = () => {
    return {
        correctives_maintenances: [],
        corrective_maintenance: {
            description: null,
        },
        validation_corrective_maintenance: {
            description: null,
        },
        search:{
            description: null,
        }
    }
}
export default getDefaultState()
