import authService from "@/services/auth/authService"
import {updateField} from "vuex-map-fields"

const mutations = {
    updateField,
    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
        const userInfo = authService.user() || state.AppActiveUser
        for (const property of Object.keys(payload)) {

            if (payload[property] !== null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.AppActiveUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }
        }

        authService.setUser(JSON.stringify(userInfo))
    },
    SET_PLATFORM(state, payload) {
        state.platform = payload
    },
}

export default mutations

