import state from './incidenceTypeState'
import actions from './incidenceTypeActions'
import mutations from './incidenceTypeMutations'
import getters from './incidenceTypeGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
