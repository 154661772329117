import api from "@/router/api"
import store from "@/store"

export default {
    listChildrenInstallationsByMaster({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.childrenInstallationsVhe.searchByMasterInstallation(payload.id)
                .then(response => {
                    const { data } = response.data
                    commit('SET_CHILDREN_INSTALLATIONS', data)

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las instalaciones GEI'
                    })
                })
        })
    },
    listAllChildrenInstallations({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.childrenInstallationsVhe.listAll(payload)
            .then(response => {
                const { data } = response.data
                commit('SET_CHILDREN_INSTALLATIONS', data.data)

                resolve(data)
            })
            .catch(() => {
                reject({
                    message: 'Error al obtener las instalaciones GEI'
                })
            })
        })
    },
    createChildrenInstallation({commit}, payload){
        commit('CLEAR_VALIDATION')

        return new Promise((resolve, reject) => {
            api.childrenInstallationsVhe.create(payload)
                .then(response => {

                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateChildrenInstallation({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.childrenInstallationsVhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteChildrenInstallation({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.childrenInstallationsVhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar la instalacion.',
                    })
                })
        })
    },
    getChildInstallation({commit}, payload){
        return new Promise((resolve, reject) => {
            api.childrenInstallationsVhe.find(payload.id, payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_CHILD_INSTALLATION', response.data.data)

                if(response.data.data.document_maintenance_children){
                    store.commit('childrenInstallationDocumentsVhe/SET_DOCUMENTS_MAINTENANCE', response.data.data.document_maintenance_children)
                }

                if(response.data.data.master_installation) {
                    store.commit('masterInstallationVhe/SET_MASTER_INSTALLATION', response.data.data.master_installation)
                }
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener la instalación',
                })
            })
        })
    },
    getAllListChildrenInstallations({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.childrenInstallationsVhe.getAllList(payload)
                .then(response => {
                    const { data } = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las instalaciones hijas'
                    })
                })
        })
    },
    exportChildrenInstallations({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.childrenInstallationsVhe.exportAllList(payload)
                .then(response => {
                    resolve(response)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las instalaciones hijas'
                    })
                })
        })
    },
}
