import state from './masterInstallationDocumentsState'
import actions from './masterInstallationDocumentsActions'
import mutations from './masterInstallationDocumentsMutations'
import getters from './masterInstallationDocumentsGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
