import state from './provinceCountryState'
import actions from './provinceCountryActions'
import mutations from './provinceCountryMutations'
import getters from './provinceCountryGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
