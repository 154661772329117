import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./inspectionMadeByState"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_INSPECTION_MADE_BYS(state, payload) {
		state.inspection_made_bys = payload
	},
	SET_INSPECTION_MADE_BY(state, payload) {
		state.inspection_made_by = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_inspection_made_by[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_document_type) {
			state.validation_inspection_made_by[key] = null
		}
	},
}
