import api from "@/router/api"

export default {
    getAllLevels1({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel1Vhe.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_LEVELS_1', response.data.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener los niveles 1',
                })
            })
        })
    },

    searchLevels1({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel1Vhe.search(payload)
                .then(response => {
                    resolve(response.data.data)

                    commit('SET_LEVELS_1', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 1',
                    })
                })
        })
    },
    findLevels1({commit}, payload){

        return new Promise((resolve, reject) => {
            api.auxiliariesLevel1Vhe.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_LEVEL_1', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los niveles 1',
                    })
                })
        })
    },

    createLevels1({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel1Vhe.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_LEVELS_1', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateLevels1({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel1Vhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })


        })
    },
    deleteLevels1({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel1Vhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar el nivel.',
                    })
                })
        })
    },
}
