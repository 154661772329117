import api from "@/router/api"

export default {
    getAllAcaeTypes({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesAcaeTypes.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_ACAE_TYPES', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener el resultado',
                })
            })
        })
    },
}
