/*
|--------------------------------------------------------------------------
| Base URLs (Donde se encuentra la API)
|--------------------------------------------------------------------------
| URL local
| http://api.endesa.local/
|
| URL clickowebs
| http://apiesmen.clickowebs.com/
|
| URL production
| https://api.documentagestor.es/
|
| URL server clicko
| http://apiendesa.clicko.cat:8880/
*/
export const baseUrl = 'https://api.documentagestor.es/'
