import state from './productState'
import actions from './productActions'
import mutations from './productMutations'
import getters from './productGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
