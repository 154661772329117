import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app/app'
import user from './user/user'
import role from './role/role'
import auth from './auth/auth'
import appConfig from './app-config/appConfig'
import verticalMenu from './vertical-menu/verticalMenu'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import state from './state'

import masterInstallation from "./gei/masterInstallation/masterInstallation"
import masterInstallationDocuments from "./gei/masterInstallationDocuments/masterInstallationDocuments"
import childrenInstallation from "./gei/childrenInstallation/childrenInstallation"
import childrenInstallationDocuments from "./gei/childrenInstallationDocuments/childrenInstallationDocuments"
import segments from "./gei/auxiliaries/segments/segment"
import orderMaintences from "./gei/auxiliaries/orderMaintenanceTypeId/orderMaintenanceTypeId"
import orderSubMaintences from "./gei/auxiliaries/orderSubMaintenanceTypeId/orderSubMaintenanceTypeId"
import industryOwnerships from "./gei/auxiliaries/industryOwnerships/industryOwnership"
import projectResponsible from "./gei/auxiliaries/projectResponsible/projectResponsible"
import responsibles from "./gei/auxiliaries/responsible/responsible"
import proyects from "./gei/auxiliaries/proyect/proyect"
import documentsTypes from "./gei/auxiliaries/documentTypes/documentType"
import level1 from "./gei/auxiliaries/level1/level1"
import level2 from "./gei/auxiliaries/level2/level2"
import level3 from "./gei/auxiliaries/level3/level3"
import level4 from "./gei/auxiliaries/level4/level4"
import clientType from "./gei/auxiliaries/clientType/clientType"
import provincesCountries from "./gei/auxiliaries/provincesCountries/provinceCountry"
import opsvaTerritories from "./gei/auxiliaries/opsvaTerritories/opsvaTerritory"
import acaeTypes from "./gei/auxiliaries/acaeTypes/acaeTypes"
import companies from  "./gei/auxiliaries/companies/company"
import maintenanceTaskTypes from "./gei/auxiliaries/maintenanceTaskTypes/maintenanceTaskType"
import typologies from "./gei/auxiliaries/typologies/typology"
import dashboardMetrics from "./gei/dashboardMetrics/dashboardMetrics"

import providers from "./gei/auxiliaries/providers/provider"
import auxiliariesProvider from "./gei/auxiliaries/providers/provider"
import clientTypes from "./gei/auxiliaries/clientType/clientType"
import auxiliariesClientTypes from "./gei/auxiliaries/clientType/clientType"
import installationTypes from "./gei/auxiliaries/installationTypes/installationType"
import auxiliariesInstallationTypes from "./gei/auxiliaries/installationTypes/installationType"
import products from "./gei/auxiliaries/products/product"
import auxiliariesProducts from "./gei/auxiliaries/products/product"
import documentTypes from "./gei/auxiliaries/documentTypes/documentType"
import auxiliariesDocumentTypes from "./gei/auxiliaries/documentTypes/documentType"
import inspectionMadeBys from "./gei/auxiliaries/inspectionMadeBy/inspectionMadeBy"
import auxiliariesInspectionMadeBy from "./gei/auxiliaries/inspectionMadeBy/inspectionMadeBy"
import auxiliariesLevel2 from "./gei/auxiliaries/level2/level2"
import auxiliaries2Responsibles from "./gei/auxiliaries/responsible/responsible"
import auxiliaries2Proyects from "./gei/auxiliaries/proyect/proyect"

//VHE
import masterInstallationVhe from "./vhe/masterInstallation/masterInstallation"
import masterInstallationDocumentsVhe from "./vhe/masterInstallationDocuments/masterInstallationDocuments"
import childrenInstallationVhe from "./vhe/childrenInstallation/childrenInstallation"
import childrenInstallationDocumentsVhe from "./vhe/childrenInstallationDocuments/childrenInstallationDocuments"
import segmentsVhe from "./vhe/auxiliaries/segments/segment"
import orderMaintencesVhe from "./vhe/auxiliaries/orderMaintenanceTypeId/orderMaintenanceTypeId"
import orderSubMaintencesVhe from "./vhe/auxiliaries/orderSubMaintenanceTypeId/orderSubMaintenanceTypeId"
import industryOwnershipsVhe from "./vhe/auxiliaries/industryOwnerships/industryOwnership"
import projectResponsibleVhe from "./vhe/auxiliaries/projectResponsible/projectResponsible"
import responsiblesVhe from "./vhe/auxiliaries/responsible/responsible"
import documentsTypesVhe from "./vhe/auxiliaries/documentTypes/documentType"
import level1Vhe from "./vhe/auxiliaries/level1/level1"
import level2Vhe from "./vhe/auxiliaries/level2/level2"
import level3Vhe from "./vhe/auxiliaries/level3/level3"
import level4Vhe from "./vhe/auxiliaries/level4/level4"
import clientTypeVhe from "./vhe/auxiliaries/clientType/clientType"
import provincesCountriesVhe from "./vhe/auxiliaries/provincesCountries/provinceCountry"
import opsvaTerritoriesVhe from "./vhe/auxiliaries/opsvaTerritories/opsvaTerritory"
import acaeTypesVhe from "./vhe/auxiliaries/acaeTypes/acaeTypes"
import companiesVhe from  "./vhe/auxiliaries/companies/company"
import maintenanceTaskTypesVhe from "./vhe/auxiliaries/maintenanceTaskTypes/maintenanceTaskType"
import typologiesVhe from "./vhe/auxiliaries/typologies/typology"
import dashboardMetricsVhe from "./vhe/dashboardMetrics/dashboardMetrics"

import incidencesTypesVhe from "./vhe/auxiliaries/incidenceTypes/incidenceType"
import providersVhe from "./vhe/auxiliaries/providers/provider"
import auxiliariesProviderVhe from "./vhe/auxiliaries/providers/provider"
import clientTypesVhe from "./vhe/auxiliaries/clientType/clientType"
import auxiliariesClientTypesVhe from "./vhe/auxiliaries/clientType/clientType"
import installationTypesVhe from "./vhe/auxiliaries/installationTypes/installationType"
import auxiliariesInstallationTypesVhe from "./vhe/auxiliaries/installationTypes/installationType"
import productsVhe from "./vhe/auxiliaries/products/product"
import auxiliariesProductsVhe from "./vhe/auxiliaries/products/product"
import documentTypesVhe from "./vhe/auxiliaries/documentTypes/documentType"
import auxiliariesDocumentTypesVhe from "./vhe/auxiliaries/documentTypes/documentType"
import auxiliariesIncidenceTypesVhe from "./vhe/auxiliaries/incidenceTypes/incidenceType"
import inspectionMadeBysVhe from "./vhe/auxiliaries/inspectionMadeBy/inspectionMadeBy"
import auxiliariesInspectionMadeByVhe from "./vhe/auxiliaries/inspectionMadeBy/inspectionMadeBy"
import auxiliariesLevel1Vhe from "./vhe/auxiliaries/level1/level1"
import auxiliariesLevel2Vhe from "./vhe/auxiliaries/level2/level2"
import auxiliariesLevel3Vhe from "./vhe/auxiliaries/level3/level3"
import auxiliariesLevel4Vhe from "./vhe/auxiliaries/level4/level4"
import auxiliaries2ResponsibleManagerVhe from "./vhe/auxiliaries2/responsibleManager/responsibleManager"
import auxiliaries2ProjectManagerVhe from "./vhe/auxiliaries2/projectManager/projectManager"
import auxiliaries2CorrectiveMaintenanceVhe from "./vhe/auxiliaries2/correctiveMaintenance/correctiveMaintenance"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		app,
		auth,
		user,
		role,
		appConfig,
		verticalMenu,

		//GEI
		auxiliariesProvider,
		auxiliariesClientTypes,
		auxiliariesInstallationTypes,
		auxiliariesProducts,
		auxiliariesDocumentTypes,
		auxiliariesInspectionMadeBy,
		auxiliariesLevel2,

		auxiliaries2Responsibles,
		auxiliaries2Proyects,

		masterInstallation,
		masterInstallationDocuments,

		childrenInstallation,
		childrenInstallationDocuments,

		segments,
		products,
		providers,
		clientTypes,
		orderMaintences,
		orderSubMaintences,
		industryOwnerships,
		installationTypes,
		documentTypes,
		inspectionMadeBys,
		projectResponsible,
		responsibles,
		proyects,
		documentsTypes,
		level1,
		level2,
		level3,
		level4,
		clientType,
		provincesCountries,
		opsvaTerritories,
		acaeTypes,
		maintenanceTaskTypes,
		companies,
		typologies,
		dashboardMetrics,

		//VHE
		auxiliariesProviderVhe,
		auxiliariesClientTypesVhe,
		auxiliariesInstallationTypesVhe,
		auxiliariesProductsVhe,
		auxiliariesDocumentTypesVhe,
		auxiliariesIncidenceTypesVhe,
		auxiliariesInspectionMadeByVhe,
		auxiliariesLevel1Vhe,
		auxiliariesLevel2Vhe,
		auxiliariesLevel3Vhe,
		auxiliariesLevel4Vhe,

		auxiliaries2ResponsibleManagerVhe,
		auxiliaries2ProjectManagerVhe,
		auxiliaries2CorrectiveMaintenanceVhe,

		masterInstallationVhe,
		masterInstallationDocumentsVhe,

		childrenInstallationVhe,
		childrenInstallationDocumentsVhe,

		segmentsVhe,
		incidencesTypesVhe,
		productsVhe,
		providersVhe,
		clientTypesVhe,
		orderMaintencesVhe,
		orderSubMaintencesVhe,
		industryOwnershipsVhe,
		installationTypesVhe,
		documentTypesVhe,
		inspectionMadeBysVhe,
		projectResponsibleVhe,
		responsiblesVhe,
		documentsTypesVhe,
		level1Vhe,
		level2Vhe,
		level3Vhe,
		level4Vhe,
		clientTypeVhe,
		provincesCountriesVhe,
		opsvaTerritoriesVhe,
		acaeTypesVhe,
		maintenanceTaskTypesVhe,
		companiesVhe,
		typologiesVhe,
		dashboardMetricsVhe
	},
	state,
	actions,
	getters,
	mutations,
	strict: process.env.DEV,
})