export default {
	SET_USERS(state, payload) {
		state.users = payload
	},
	SET_NOTIFICATIONS(state, payload) {
		state.notifications = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation) {
			state.validation[key] = null
		}
	},
}
