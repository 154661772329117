import state from './dashboardMetricsState'
import actions from './dashboardMetricsActions'
import mutations from './dashboardMetricsMutations'
import getters from './dashboardMetricsGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
