import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./childrenInstallationState"
import helper from "@/resources/helpers/helpers"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_CHILDREN_INSTALLATIONS(state, payload) {
        state.children_installations = payload
    },
    SET_CHILD_INSTALLATION(state, payload) {
        state.child_installation = payload

        let dates = ['start_date', 'end_date']

        dates.forEach(date => {
            state.child_installation[date] = (payload[date]) ? helper.formatDate(payload[date]) : null
        })
    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation_child_installation[key] = '* '+payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation_child_installation) {
            state.validation_child_installation[key] = null
        }
    },
}