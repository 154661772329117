import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./projectManagerState"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_PROJECTS_MANAGERS(state, payload) {
		state.projects_managers = payload
	},
	SET_PROJECT_MANAGER(state, payload) {
		state.project_manager = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_project_manager[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_responsible_manager) {
			state.validation_project_manager[key] = null
		}
	},
}
