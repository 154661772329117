import state from './maintenanceTaskTypeState'
import actions from './maintenanceTaskTypeActions'
import mutations from './maintenanceTaskTypeMutations'
import getters from './maintenanceTaskTypeGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
