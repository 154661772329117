export const getDefaultState = () => {
    return {
        maintenance_task_types: [],
        maintenance_task_type: {

        },
        validation_maintenance_task_type: {

        },
    }
}
export default getDefaultState()
