import api from "@/router/api"

export default {
    getAllCompanies({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesCompanies.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_COMPANIES', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener las empresas',
                })
            })
        })
    },
}
