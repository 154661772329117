import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./level4State"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_LEVELS_4(state, payload) {
		state.levels = payload
	},
	SET_LEVEL_4(state, payload) {
		state.level = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_level_4[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_level_4) {
			state.validation_level_4[key] = null
		}
	},
}
