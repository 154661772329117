import state from './documentTypeState'
import actions from './documentTypeActions'
import mutations from './documentTypeMutations'
import getters from './documentTypeGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
