import state from './childrenInstallationDocumentsState'
import actions from './childrenInstallationDocumentsActions'
import mutations from './childrenInstallationDocumentsMutations'
import getters from './childrenInstallationDocumentsGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
