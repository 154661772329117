import {updateField} from "vuex-map-fields"
import {getDefaultState, getDefaultStateContact} from "./providerState"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	RESET_STATE_CONTACT(state) {
		Object.assign(state, getDefaultStateContact())
	},
	SET_PROVIDERS(state, payload) {
		state.providers = payload
	},
	SET_PROVIDER(state, payload) {
		state.provider = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_provider[key] = '* '+payload[key][0]
		}
	},
	SET_VALIDATION_CONTACTS_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_contact[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_provider) {
			state.validation_provider[key] = null
		}
	},
	CLEAR_VALIDATION_CONTACT(state) {
		for (const key in state.validation_contact) {
			state.validation_contact[key] = null
		}
	}
}
