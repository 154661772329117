export const getDefaultState = () => {
    return {
        opsva_territories: [],
        opsva_territory: {

        },
        validation_opsva_territory: {

        },
    }
}
export default getDefaultState()
