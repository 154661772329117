export const getDefaultState = () => {
    return {
        responsibles: [],
        responsible: {
            name: null,
            observations: null,
            email: null,
            phone: null,

        },
        validation_responsible: {
            name: null,
            observations: null,
        },
        search: {
            name: null,
        },
    }
}
export default getDefaultState()
