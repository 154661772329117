import api from "@/router/api"

export default {
    getAllProvincesByCountry({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProvincesCountries.listByCountry(payload.countryId,payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_PROVINCES', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener las provincias',
                })
            })
        })
    },
    getAllCountries({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesProvincesCountries.listCountries(payload)
            .then(response => {
                resolve(response.data.data)

               commit('SET_COUNTRIES', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener los paises',
                })
            })
        })
    },
}
