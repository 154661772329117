import state from './typologyState'
import actions from './typologyActions'
import mutations from './typologyMutations'
import getters from './typologyGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
