import state from './level1State'
import actions from './level1Actions'
import mutations from './level1Mutations'
import getters from './level1Getters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
