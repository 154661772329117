import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./proyectState"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_PROYECTS(state, payload) {
		state.proyects = payload
	},
	SET_PROYECT(state, payload) {
		state.proyect = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_proyect[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_proyect) {
			state.validation_proyect[key] = null
		}
	},
}
