import api from "@/router/api"

export default {
    searchCorrectiveMaintenance({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliaries2CorrectiveMaintenanceVhe.search(payload)
                .then(response => {
                    resolve(response.data.data.data)
                    commit('SET_CORRECTIVES_MAINTENANCES', response.data.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener las incidencias',
                    })
                })
        })
    },
    findCorrectiveMaintenance({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliaries2CorrectiveMaintenanceVhe.find(payload)
                .then(response => {
                    resolve(response.data.data)
                    commit('SET_CORRECTIVE_MAINTENANCE', response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener las incidencias',
                    })
                })
        })
    },

    createCorrectiveMaintenance({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliaries2CorrectiveMaintenanceVhe.create(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_CORRECTIVES_MAINTENANCES', response.data.data)
                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    updateCorrectiveMaintenance({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.auxiliaries2CorrectiveMaintenanceVhe.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    deleteCorrectiveMaintenance({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.auxiliaries2CorrectiveMaintenanceVhe.delete(payload.id)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al eliminar la incidencia.',
                    })
                })
        })
    },
}
