export const getDefaultState = () => {
    return {
        client_types: [],
        client_type: {
            type: null,
        },
        validation_client_type: {
            type: null,
        },
        search: {
            type: null,
        }
    }
}
export default getDefaultState()
