import api from "@/router/api"
import store from "@/store"

export default {
	getDashboardMetrics({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.dashboardMetricsVhe.getDashboardMetrics(payload)
				.then(response => {

					const { data } = response.data
					commit('SET_DASHBOARD_METRICS', data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener el cuadro de mandos'
					})
				})
		})
	},

	generateInformDocumentExpirationExcel({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.dashboardMetricsVhe.generateInformDocumentExpirationExcel(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({
						message
					})
				})
		})
	},

	getMaintenanceMetrics({commit}, payload) {
		commit('SET_MAINTENANCE_LIST', [])

		return new Promise((resolve, reject) => {
			api.dashboardMetricsVhe.getMaintenanceMetrics(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_MAINTENANCE_LIST', data)

					resolve(response.data)
				})
				.catch(error => {
					console.log(error)
					const {data: {message}} = error.response
					reject({
						message
					})
				})
		})
	},

	getMaintenancePlannedMetrics({commit}, payload) {
		commit('SET_MAINTENANCE_LIST', [])

		return new Promise((resolve, reject) => {
			api.dashboardMetricsVhe.getMaintenancePlannedMetrics(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_MAINTENANCE_LIST', data)

					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({
						message
					})
				})
		})
	},
}
