import authService from "@/services/auth/authService"

const userInfoLocalStorage = authService.user() || {}

const getUserInfo = () => {
    const userInfo = {}

    Object.keys(userInfoLocalStorage).forEach(key => {
        if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
    })

    return userInfo
}

const state = {
    AppActiveUser: getUserInfo(),
    platform: null,

    pageActiveUsersPersons: 1,
    searchTermActiveUsersPersons: '',
    pageLengthActiveUsersPersons: 100,

    pageActivePersons: 1,
    searchTermActivePersons: '',
    pageLengthActivePersons: 100,

    page: 1,
    searchTerm: '',
    pageLength: 10,
}

export default state
