import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./acaeTypes"

export default {
	updateField,
	SET_ACAE_TYPES(state, payload) {
		state.acae_types = payload
	},
	SET_ACAE_TYPE(state, payload) {
		state.acae_type = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_acae_type[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_acae_type) {
			state.validation_acae_type[key] = null
		}
	},
}
