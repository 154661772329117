import api from "@/router/api"

export default {
    getAllLevels1({commit}, payload){
        return new Promise((resolve, reject) => {
            api.auxiliariesLevel1.list(payload)
            .then(response => {
                resolve(response.data.data)

                commit('SET_LEVELS_1', response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener los niveles 1',
                })
            })
        })
    },
}
