import state from './companyState'
import actions from './companyActions'
import mutations from './companyMutations'
import getters from './companyGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
