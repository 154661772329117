export const getDefaultState = () => {
    return {
        context: null,

        documents_client_contract_e_e: [],
        documents_by_route_client_contract_e_e: [],

        documents_client_delivery: [],
        documents_by_route_client_delivery: [],

        documents_transfer_certificate: [],
        documents_by_route_transfer_certificate: [],

        documents_client_installation_risk: [],
        documents_by_route_client_installation_risk: [],

        documents_client_emergency: [],
        documents_by_route_client_emergency: [],

    }
}
export default getDefaultState()
