import axios from '@/resources/libs/axios'

export default {
    list() {
        return axios.get(`/gei/auxiliaries/responsibles/list/all`)
    },
    search(data) {
        return axios.get(`/gei/auxiliaries2/responsibles/search`, { params: data })
    },
    find(data) {
        return axios.get(`/gei/auxiliaries2/responsibles/find/${data.id}`,{ params: data })
    },
    create(data) {
        return axios.post(`/gei/auxiliaries2/responsibles/create`,  data)
    },
    update(data) {
        return axios.post(`/gei/auxiliaries2/responsibles/update`,  data)
    },
    delete(id) {
        return axios.delete(`/gei/auxiliaries2/responsibles/delete/${id}`)
    },
}