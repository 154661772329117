import state from './projectResponsibleState'
import actions from './projectResponsibleActions'
import mutations from './projectResponsibleMutations'
import getters from './projectResponsibleGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
