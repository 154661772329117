import state from './masterInstallationState'
import actions from './masterInstallationActions'
import mutations from './masterInstallationMutations'
import getters from './masterInstallationGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
