import {
	PAGE_404, LOGIN, REGISTER, FORGOT_PASSWORD, NOT_AUTHORIZED, UNDER_MAINTENANCE, ERROR,
} from '../constants'

export default [
	{
		path: PAGE_404,
		name: 'error-404',
		component: () => import('@/views/error/Error404.vue'),
		meta: {
			layout: 'full',
			resource: 'Auth',
			action: 'read',
		},
	},
	{
		path: LOGIN,
		name: 'auth-login',
		component: () => import('@/views/pages/authentication/Login.vue'),
		meta: {
			layout: 'full',
			resource: 'Auth',
			redirectIfLoggedIn: true,
		},
	},
	{
		path: REGISTER,
		name: 'auth-register',
		component: () => import('@/views/pages/authentication/Register.vue'),
		meta: {
			layout: 'full',
			resource: 'Auth',
			redirectIfLoggedIn: true,
		},
	},
	{
		path: FORGOT_PASSWORD,
		name: 'auth-forgot-password',
		component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
		meta: {
			layout: 'full',
			resource: 'Auth',
			redirectIfLoggedIn: true,
		},
	},
	{
		path: NOT_AUTHORIZED,
		name: 'misc-not-authorized',
		component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
		meta: {
			layout: 'full',
			resource: 'Auth',
		},
	},
	{
		path: UNDER_MAINTENANCE,
		name: 'misc-under-maintenance',
		component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
		meta: {
			layout: 'full',
		},
	},
	{
		path: ERROR,
		name: 'misc-error',
		component: () => import('@/views/pages/miscellaneous/Error.vue'),
		meta: {
			layout: 'full',
		},
	},
	{
		path: '*',
		redirect: 'error-404',
	},
]
