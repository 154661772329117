import axios from '@/resources/libs/axios'

export default {
    list(data) {
        return axios.get(`/vhe/auxiliaries2/projectManager/list/all`, { params: data })
    },
    search(data) {
        return axios.get(`/vhe/auxiliaries2/projectManager/list`,{ params: data })
    },
    find(data) {
        return axios.get(`/vhe/auxiliaries2/projectManager/find/${data.id}`,{ params: data })
    },
    create(data) {
        return axios.post(`/vhe/auxiliaries2/projectManager/create`,  data)
    },
    update(data) {
        return axios.post(`/vhe/auxiliaries2/projectManager/update`,  data)
    },
    delete(id) {
        return axios.delete(`/vhe/auxiliaries2/projectManager/delete/${id}`)
    },
}