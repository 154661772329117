import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./installationTypeState"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_INSTALLATION_TYPES(state, payload) {
		state.installation_types = payload
	},
	SET_INSTALLATION_TYPE(state, payload) {
		state.installation_type = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_installation_type[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_installation_type) {
			state.validation_installation_type[key] = null
		}
	},

}
