import api from '@/router/api'
import authService, {ACTIVE_PLATFORM} from '@/services/auth/authService'
import useJwt from "@/services/auth/jwt/useJwt"
import {initialAbility} from "@/resources/libs/acl/config"

export default {
	login({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			const {email, password, platform_id} = payload
			api.auth.login(email, password, platform_id)
			  .then(response => {
				  const {data} = response.data
				  data.user.permissions = data.permissions

				  dispatch('formalizeLogin', {
					  access_token: data.access_token,
					  expires_in: data.expires_in,
					  user: data.user,
					  platform: data.platform,
				  })

				  resolve(response.data)
			  })
			  .catch(error => {
				  const {data: {message}} = error.response
				  // eslint-disable-next-line prefer-promise-reject-errors
				  reject({message})
			  })
		})
	},

	loginWithGivenToken({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			authService.setBearerCode(payload.access_token)

			this.dispatch('user/getAuthUser', payload)
			  .then(user => {
				  dispatch('formalizeLogin', {
					  access_token: payload.access_token,
					  expires_in: payload.expires_in,
					  employee: user.employee,
                      user,
                  })

				  resolve()
			  })
			  .catch(error => {
				  reject(error.message)
			  })
		})
	},

	updateUserData({commit, dispatch}, payload) {
		const user = JSON.parse(localStorage.getItem('userData'))
		return new Promise((resolve, reject) => {
			console.log('--------')
			console.log(user)
			console.log(localStorage.getItem(ACTIVE_PLATFORM))
			console.log('--------')

			api.auth.updateUserData(user.id, localStorage.getItem(ACTIVE_PLATFORM))
				.then(response => {
					const {data} = response.data
					data.user.permissions = data.permissions

					commit('UPDATE_USER_INFO', data.user, {root: true})

					resolve(response.data)
				})
				.catch(error => {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(error)
				})
		})
	},

	formalizeLogin({commit}, {access_token, expires_in, user, platform}) {
		authService.setAccessToken(access_token)
		authService.setExpiry(expires_in)
		authService.setBearerCode(access_token)
		authService.setActivePlatform(platform)

		// Update user details
		commit('UPDATE_USER_INFO', user, {root: true})
	},

	logout() {
		authService.logOut()
		authService.redirectToLogin()
	},

	// eslint-disable-next-line no-unused-vars
	forgotPassword({dispatch}, email) {
		return new Promise((resolve, reject) => {
			api.forgotPassword(email)
			  .then(response => {
				  const {message, data} = response.data
				  const {status} = data

				  resolve({message, status})
			  })
			  .catch(error => {
				  const {data: {message}} = error.response
				  // eslint-disable-next-line prefer-promise-reject-errors
				  reject({message})
			  })
		})
	},

	// eslint-disable-next-line no-unused-vars
	resetPassword({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			const { token, email, password, password_confirmation } = payload
			api.resetPassword(token, email, password, password_confirmation)
			  .then(response => {
				  const {message} = response.data

				  resolve({message})
			  })
			  .catch(error => {
				  const {data, status} = error.response
				  const {message: title} = data

				  let text = null
				  if (status === 422) {
					  text = Object.values(data.errors).join(' ')
				  }
				  // eslint-disable-next-line prefer-promise-reject-errors
				  reject({title, text})
			  })
		})
	},

	refresh() {
		return new Promise((resolve, reject) => {
			api.refresh()
			  .then(response => {
				  const {data} = response.data

				  authService.setAccessToken(data.access_token)
				  authService.setExpiry(data.expires_in)
				  authService.setBearerCode(data.access_token)
				  authService.setActivePlatform(data.platform)
			  })
			  .catch(error => {
				  const {data: {message}} = error.response
				  // eslint-disable-next-line prefer-promise-reject-errors
				  reject({message})
			  })
		})
	},

	getRoles(){
		return new Promise((resolve, reject) => {
			api.roles.list()
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({message})
				})
		})
	},

	getRolesByCenter({commit}, payload){
		return new Promise((resolve, reject) => {
			api.roles.listByCenter(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({message})
				})
		})
	},
	setPlatform({commit, dispatch}, payload) {
		return new Promise((resolve, reject) => {
			api.auth.setPlatform(payload.id)
			.then(response => {
				const {data} = response.data
				data.user.permissions = data.permissions

				commit('UPDATE_USER_INFO', data.user, {root: true})


				resolve(response.data)
			})
			.catch(error => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject(error)
			})
		})
	},
}
