import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./responsibleManagerState"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_RESPONSIBLES_MANAGERS(state, payload) {
		state.responsibles_managers = payload
	},
	SET_RESPONSIBLE_MANAGER(state, payload) {
		state.responsible_manager = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_responsible_manager[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_responsible_manager) {
			state.validation_responsible_manager[key] = null
		}
	},
}
