import axios from '@/resources/libs/axios'

export default {
    list(data) {
        return axios.get(`/gei/auxiliaries/installation-types/list/all`, { params: data })
    },
    search(data) {
        return axios.get(`/gei/auxiliaries/installation-types/list`,{ params: data })
    },
    find(data) {
        return axios.get(`/gei/auxiliaries/installation-types/find/${data.id}`,{ params: data })
    },
    create(data) {
        return axios.post(`/gei/auxiliaries/installation-types/create`,  data)
    },
    update(data) {
        return axios.post(`/gei/auxiliaries/installation-types/update`,  data)
    },
    delete(id) {
        return axios.delete(`/gei/auxiliaries/installation-types/delete/${id}`)
    },
}