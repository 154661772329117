import axios from '@/resources/libs/axios'

export default {
	//Documents

	updateRecordGeneralData(data) {
		return axios.post(`/vhe/master-installation/general-data/${data.context}/edit`,  data)
	},
	createRecordGeneralData(data) {
		return axios.post(`/vhe/master-installation/general-data/${data.context}/create`,  data)
	},
	getDocumentsListByRoute(id, data) {
		return axios.get(`/vhe/master-installation/general-data/${data.context}/get-documents-by-route/${id}`)
	},
	uploadDocuments(id, data) {
		return axios.post(`/vhe/master-installation/general-data/${data.context}/upload-documents/${id}`, data)
	},
	deleteRecord(id, data) {
		return axios.delete(`/vhe/master-installation/general-data/${data.context}/delete/${id}`)
	},
	deleteDocument(data) {
		return axios.delete(`/vhe/master-installation/general-data/${data.context}/delete-document`, {params: data})
	},
}