export const getDefaultState = () => {
    return {
        providers: [],
        provider: {
            address: null,
            cif: null,
            city: null,
            code: null,
            document_order_number: null,
            email: null,
            id: null,
            observations: null,
            phone_24: null,
            postal_code: null,
            provider: null,
            provider_type_id: null,
            province_id: null,
            saga_code: null,
            unsubscribe: 0,
        },
        validation_provider: {
            address: null,
            cif: null,
            city: null,
            code: null,
            document_order_number: null,
            email: null,
            id: null,
            observations: null,
            phone_24: null,
            postal_code: null,
            provider: null,
            provider_type_id: null,
            province_id: null,
            saga_code: null,
            unsubscribe: 0,
        },
        search: {
            cif: null,
            code: null,
            email: null,
        },



        validation_contact: {
            id: null,
            name: null,
            surnames: null,
            job_type: null,
            phone_1: null,
            phone_2: null,
            email: null,
            management_contact: null,
            management_cat: null,
            provider_id: null,
        }
    }
}

export const getDefaultStateContact = () => {
    return {
        contact: {
            id: null,
            name: null,
            surnames: null,
            job_type: null,
            phone_1: null,
            phone_2: null,
            email: null,
            management_contact: 0,
            management_cat: 0,
            provider_id: null,
        },
    }
}

export default { ...getDefaultState(), ...getDefaultStateContact() }
