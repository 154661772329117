import axios from '@/resources/libs/axios'

export default {
    search(data) {
        return axios.get(`/gei/auxiliaries/products/list`, { params: data })
    },
    find(data) {
        return axios.get(`/gei/auxiliaries/products/find/${data.id}`,{ params: data })
    },
    create(data) {
        return axios.post(`/gei/auxiliaries/products/create`,  data)
    },
    update(data) {
        return axios.post(`/gei/auxiliaries/products/update`,  data)
    },
    delete(id) {
        return axios.delete(`/gei/auxiliaries/products/delete/${id}`)
    },
    listSubProducts(id){
        return axios.get(`/gei/auxiliaries/products/subproducts/list/${id}`)
    },
    createSubProducts(data){
        return axios.post(`/gei/auxiliaries/products/subproducts/create`,  data)
    },
    updateSubProducts(data){
        return axios.post(`/gei/auxiliaries/products/subproducts/update`,  data)
    },
    deleteSubProducts(id) {
        return axios.delete(`/gei/auxiliaries/products/subproducts/delete/${id}`)
    },
}