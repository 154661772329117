import {
	GEI_AUXILIAR_PROVIDERS_LIST,
	GEI_AUXILIAR_PROVIDERS_CREATE,
	GEI_AUXILIAR_PROVIDERS_EDIT,
	GEI_AUXILIAR_CLIENTTYPES_LIST,
	GEI_AUXILIAR_CLIENTTYPES_CREATE,
	GEI_AUXILIAR_CLIENTTYPES_EDIT,
	GEI_AUXILIAR_INSTALLATIONTYPES_LIST,
	GEI_AUXILIAR_INSTALLATIONTYPES_CREATE,
	GEI_AUXILIAR_INSTALLATIONTYPES_EDIT,
	GEI_AUXILIAR_PRODUCTS_LIST,
	GEI_AUXILIAR_PRODUCTS_CREATE,
	GEI_AUXILIAR_PRODUCTS_EDIT,
	GEI_AUXILIAR_DOCUMENTTYPES_LIST,
	GEI_AUXILIAR_DOCUMENTTYPES_CREATE,
	GEI_AUXILIAR_DOCUMENTTYPES_EDIT,
	GEI_AUXILIAR_INSPECTIONMADEBY_LIST,
	GEI_AUXILIAR_INSPECTIONMADEBY_CREATE,
	GEI_AUXILIAR_INSPECTIONMADEBY_EDIT,
	GEI_AUXILIAR_LEVEL2_LIST,
	GEI_AUXILIAR_LEVEL2_CREATE,
	GEI_AUXILIAR_LEVEL2_EDIT,
} from '../../constants'

export default [
	// PROVEEDORES
	{
		path: GEI_AUXILIAR_PROVIDERS_LIST,
		name: 'list_auxiliar_providers_gei',
		component: () => import('@/views/gei/auxiliars_providers/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de Proveedores',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado de Proveedores',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR_PROVIDERS_CREATE,
		name: 'create_auxiliar_providers_gei',
		component: () => import('@/views/gei/auxiliars_providers/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo proveedor',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Proveedores',
					to: GEI_AUXILIAR_PROVIDERS_LIST,
				},
				{
					text: 'Añadir nuevo proveedor',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR_PROVIDERS_EDIT}/:id`,
		name: 'auxiliar_providers_edit_gei',
		component: () => import('@/views/gei/auxiliars_providers/form/formView.vue'),
		meta: {
			pageTitle: 'Proveedores',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Proveedores',
					to: GEI_AUXILIAR_PROVIDERS_LIST,
				},
				{
					text: 'Editar Proveedor',
					active: true,
				},
			],
		},
	},
	// END PROVEEDORES

	// 	TIPOS DE CLIENTES
	{
		path: GEI_AUXILIAR_CLIENTTYPES_LIST,
		name: 'list_auxiliar_clientTypes_gei',
		component: () => import('@/views/gei/auxiliars_clientTypes/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de tipos de clientes',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado de tipos de clientes',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR_CLIENTTYPES_CREATE,
		name: 'create_auxiliar_clientTypes_gei',
		component: () => import('@/views/gei/auxiliars_clientTypes/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo tipo de cliente',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Tipo de cliente',
					to: GEI_AUXILIAR_CLIENTTYPES_LIST,
				},
				{
					text: 'Añadir nuevo tipo de cliente',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR_CLIENTTYPES_EDIT}/:id`,
		name: 'auxiliar_clientTypes_edit_gei',
		component: () => import('@/views/gei/auxiliars_clientTypes/form/formView.vue'),
		meta: {
			pageTitle: 'Editar Tipo de cliente',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Tipo de cliente',
					to: GEI_AUXILIAR_CLIENTTYPES_LIST,
				},
				{
					text: 'Editar Tipo de cliente',
					active: true,
				},
			],
		},
	},
	// 	END TIPOS DE CLIENTES

	// 	TIPOS DE INSTALACIONES
	{
		path: GEI_AUXILIAR_INSTALLATIONTYPES_LIST,
		name: 'list_auxiliar_installationTypes_gei',
		component: () => import('@/views/gei/auxiliars_installationTypes/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de tipos de instalaciones',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado de tipos de instalaciones',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR_INSTALLATIONTYPES_CREATE,
		name: 'create_auxiliar_installationTypes_gei',
		component: () => import('@/views/gei/auxiliars_installationTypes/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo tipo de instalación',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Tipo de instalación',
					to: GEI_AUXILIAR_INSTALLATIONTYPES_LIST,
				},
				{
					text: 'Añadir nuevo tipo de instalación',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR_INSTALLATIONTYPES_EDIT}/:id`,
		name: 'auxiliar_installationTypes_edit_gei',
		component: () => import('@/views/gei/auxiliars_installationTypes/form/formView.vue'),
		meta: {
			pageTitle: 'Editar Tipo de instalación',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Tipo de instalación',
					to: GEI_AUXILIAR_INSTALLATIONTYPES_LIST,
				},
				{
					text: 'Editar Tipo de instalación',
					active: true,
				},
			],
		},
	},
	// 	END TIPOS DE INSTALACIONES

	// 	PRODUCTOS
	{
		path: GEI_AUXILIAR_PRODUCTS_LIST,
		name: 'list_auxiliar_products_gei',
		component: () => import('@/views/gei/auxiliars_products/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de productos',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado de productos',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR_PRODUCTS_CREATE,
		name: 'create_auxiliar_products_gei',
		component: () => import('@/views/gei/auxiliars_products/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo producto',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Productos',
					to: GEI_AUXILIAR_PRODUCTS_LIST,
				},
				{
					text: 'Añadir nuevo producto',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR_PRODUCTS_EDIT}/:id`,
		name: 'auxiliar_products_edit_gei',
		component: () => import('@/views/gei/auxiliars_products/form/formView.vue'),
		meta: {
			pageTitle: 'Editar un producto',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Productos',
					to: GEI_AUXILIAR_PRODUCTS_LIST,
				},
				{
					text: 'Editar producto',
					active: true,
				},
			],
		},
	},
	//  END PRODUCTOS

	// 	TIPO DE DOCUMENTOS
	{
		path: GEI_AUXILIAR_DOCUMENTTYPES_LIST,
		name: 'list_auxiliar_documentTypes_gei',
		component: () => import('@/views/gei/auxiliars_documentTypes/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de tipos de documentos',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado de tipos de documentos',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR_DOCUMENTTYPES_CREATE,
		name: 'create_auxiliar_documentTypes_gei',
		component: () => import('@/views/gei/auxiliars_documentTypes/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo tipo de documento',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Tipos de documentos',
					to: GEI_AUXILIAR_DOCUMENTTYPES_LIST,
				},
				{
					text: 'Añadir nuevo tipo de documento',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR_DOCUMENTTYPES_EDIT}/:id`,
		name: 'auxiliar_documentTypes_edit_gei',
		component: () => import('@/views/gei/auxiliars_documentTypes/form/formView.vue'),
		meta: {
			pageTitle: 'Editar un tipo de documento',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Productos',
					to: GEI_AUXILIAR_DOCUMENTTYPES_LIST,
				},
				{
					text: 'Editar tipo de documento',
					active: true,
				},
			],
		},
	},
	//  END TIPO DE DOCUMENTOS

	// 	INSPECCIÓN REALIZADA POR
	{
		path: GEI_AUXILIAR_INSPECTIONMADEBY_LIST,
		name: 'list_auxiliar_inspectionMadeBy_gei',
		component: () => import('@/views/gei/auxiliars_inspectionMadeBy/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de inspecciones realizadas',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado de inspecciones realizadas',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR_INSPECTIONMADEBY_CREATE,
		name: 'create_auxiliar_inspectionMadeBy_gei',
		component: () => import('@/views/gei/auxiliars_inspectionMadeBy/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nueva inspección realizada',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Tipos de documentos',
					to: GEI_AUXILIAR_INSPECTIONMADEBY_LIST,
				},
				{
					text: 'Añadir nueva inspección realizada',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR_INSPECTIONMADEBY_EDIT}/:id`,
		name: 'auxiliar_inspectionMadeBy_edit_gei',
		component: () => import('@/views/gei/auxiliars_inspectionMadeBy/form/formView.vue'),
		meta: {
			pageTitle: 'Editar inspección realizada',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Inspección Realizada',
					to: GEI_AUXILIAR_INSPECTIONMADEBY_LIST,
				},
				{
					text: 'Editar inspección realizada',
					active: true,
				},
			],
		},
	},
	//  END INSPECCIÓN REALIZADA POR

	// 	NIVEL 2
	{
		path: GEI_AUXILIAR_LEVEL2_LIST,
		name: 'list_auxiliar_level2_gei',
		component: () => import('@/views/gei/auxiliars_level2/list/listView.vue'),
		meta: {
			pageTitle: 'Listado del nivel',
			permission: 'list_gei_providers',
			breadcrumb: [
				{
					text: 'Listado del nivel',
					active: true,
				},
			],
		},
	},
	{
		path: GEI_AUXILIAR_LEVEL2_CREATE,
		name: 'create_auxiliar_level2_gei',
		component: () => import('@/views/gei/auxiliars_level2/form/formView.vue'),
		meta: {
			pageTitle: 'Añadir nuevo nivel',
			permission: 'create_gei_providers',
			breadcrumb: [
				{
					text: 'Nivel 2',
					to: GEI_AUXILIAR_LEVEL2_LIST,
				},
				{
					text: 'Añadir nuevo nivel',
					active: true,
				},
			],
		},
	},
	{
		path: `${GEI_AUXILIAR_LEVEL2_EDIT}/:id`,
		name: 'auxiliar_level2_edit_gei',
		component: () => import('@/views/gei/auxiliars_level2/form/formView.vue'),
		meta: {
			pageTitle: 'Editar nivel',
			permission: 'edit_gei_providers',
			breadcrumb: [
				{
					text: 'Nivel 2',
					to: GEI_AUXILIAR_LEVEL2_LIST,
				},
				{
					text: 'Editar nivel',
					active: true,
				},
			],
		},
	},
	//  END NIVEL 2
]
