export const getDefaultState = () => {
    return {
        levels_3: [],
        level_3: {

        },
        validation_level_3: {

        },
    }
}
export default getDefaultState()
