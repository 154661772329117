export const getDefaultState = () => {
    return {
        segments: [],
        segment: {

        },
        validation_segment: {

        },
    }
}
export default getDefaultState()
