export const getDefaultState = () => {
    return {
        context: null,

        //Mantenimiento
        document_maintenance_active: null,

        documents_maintenance: [],
        documents_by_route_maintenance: [],

        //Mantenimiento - inspeccion
        documents_maintenance_inspection: [],
        documents_by_route_maintenance_inspection: [],

        //Mantenimiento - insitu
        documents_maintenance_insitu: [],
        documents_by_route_maintenance_insitu: [],

        //Mantenimiento - invigilando
        documents_maintenance_invigilando: [],
        documents_by_route_maintenance_invigilando: [],

    }
}
export default getDefaultState()
