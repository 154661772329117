export default {
	users: [],
	notifications: [],
	validation: {
		name: null,
		email: null,
		password: null,
		roles: null,
	},
}
