import {updateField} from "vuex-map-fields"
import helper from "@/resources/helpers/helpers"

export default {
	updateField,
	SET_DOCUMENTS_PROVIDER_CONTRACT_EE(state, payload) {
		state.documents_provider_contract_e_e = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_provider_contract_e_e[key] = value

			let dates = ['contract_date','start_date', 'end_date']
			dates.forEach(date => {
				state.documents_provider_contract_e_e[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_PROVIDER_CONTRACT_EE(state, payload) {
		state.documents_by_route_provider_contract_e_e = payload
	},
	SET_DOCUMENTS_SPECIFIC_PREVENTION(state, payload) {
		state.documents_specific_prevention = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_specific_prevention[key] = value

			let dates = ['received_date','exam_date']
			dates.forEach(date => {
				state.documents_specific_prevention[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_SPECIFIC_PREVENTION(state, payload) {
		state.documents_by_route_specific_prevention = payload
	},
	SET_DOCUMENTS_IDENTIFICATION_RISK_EE(state, payload) {
		state.documents_identification_risk_e_e = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_identification_risk_e_e[key] = value

			let dates = ['effective_date', 'request_date']
			dates.forEach(date => {
				state.documents_identification_risk_e_e[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_IDENTIFICATION_RISK_EE(state, payload) {
		state.documents_by_route_identification_risk_e_e = payload
	},
	SET_DOCUMENTS_BY_ROUTE_WORKPLACE_EE(state, payload) {
		state.documents_by_route_workplace_e_e = payload
	},
	SET_DOCUMENTS_WORKPLACE_EE(state, payload) {
		state.documents_workplace_e_e = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_workplace_e_e[key] = value

			let dates = ['client_delivery_date','provider_delivery_date', 'spm_request_date', 'received_date']
			dates.forEach(date => {
				state.documents_workplace_e_e[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_EMERGENCY_PLAN_EE(state, payload) {
		state.documents_by_route_emergency_plan_e_e = payload
	},
	SET_DOCUMENTS_EMERGENCY_PLAN_EE(state, payload) {
		state.documents_emergency_plan_e_e = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_emergency_plan_e_e[key] = value

			let dates = ['received_date','document_date', 'provider_delivery_date']
			dates.forEach(date => {
				state.documents_emergency_plan_e_e[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_ACAE(state, payload) {
		state.documents_by_route_acae = payload
	},
	SET_DOCUMENTS_ACAE(state, payload) {
		state.documents_acae = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_acae[key] = value

			let dates = ['effective_date']
			dates.forEach(date => {
				state.documents_acae[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE(state, payload) {
		state.documents_by_route_maintenance = payload
	},
	SET_DOCUMENTS_MAINTENANCE(state, payload) {
		state.documents_maintenance = payload

		Object.entries(payload).forEach(([key, value]) => {

			state.documents_maintenance[key] = value

			let dates = ['effective_date', 'expected_date', 'report_review_date']
			dates.forEach(date => {
				state.documents_maintenance[key][date] = (value[date]) ? helper.formatDate(value[date]) : null
			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INSPECTION(state, payload) {
		state.documents_by_route_maintenance_inspection = payload
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INSITU(state, payload) {
		state.documents_by_route_maintenance_insitu = payload
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_ATEX(state, payload) {
		state.documents_by_route_maintenance_atex = payload
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MAINTENANCE_INVIGILANDO(state, payload) {
		state.documents_by_route_maintenance_invigilando = payload
	},
	SET_DOCUMENT_INSPECTION(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_inspection = [formattedPayload]

	},
	SET_DOCUMENT_INSITU(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object
		
		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_insitu = [formattedPayload]

	},
	SET_DOCUMENT_ATEX(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_atex = [formattedPayload]

	},
	SET_DOCUMENT_INVIGILANDO(state, payload) {
		const formattedPayload = { ...payload } // Clone the payload object

		if (formattedPayload.effective_date) {
			formattedPayload.effective_date = helper.formatDate(formattedPayload.effective_date)
		}

		state.documents_maintenance_invigilando = [formattedPayload]

	},
	SET_ACTIVE_MAINTENANCE(state, payload) {
		state.document_maintenance_active = payload
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_MANUALPROJECT(state, payload) {
		state.documents_by_route_manualproject = payload
	},
	SET_DOCUMENTS_MANUALPROJECT(state, payload) {
		state.documents_manualproject = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_manualproject[key] = value

			let dates = ['document_date']
			dates.forEach(date => {
				state.documents_manualproject[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_ORDER(state, payload) {
		state.documents_by_route_order = payload
	},
	SET_DOCUMENTS_ORDER(state, payload) {
		state.documents_order = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_order[key] = value

			let dates = ['start_date','end_date','request_date']
			dates.forEach(date => {
				state.documents_order[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
	SET_DOCUMENTS_BY_ROUTE_DOCUMENTS_OCA(state, payload) {
		state.documents_by_route_oca = payload
	},
	SET_DOCUMENTS_OCA(state, payload) {
		state.documents_oca = payload
		Object.entries(payload).forEach(([key, value]) => {

			state.documents_oca[key] = value

			let dates = ['effective_date', 'expected_date']
			dates.forEach(date => {
				state.documents_oca[key][date] = (value[date]) ? helper.formatDate(value[date]) : null

			})
		})
	},
}
